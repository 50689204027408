import { getAIGateway } from '@/api/configuration'
import GatewayAI from '@/assets/ai_gateway_ai.png'
import GatewayPlane from '@/assets/ai_gateway_plane.png'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx'
import { useToast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import {
    GatewayCodeExamples,
    type GatewayCodeType,
    GatewayProviders,
    type GatewayProvidersType,
} from '@/lib/AIGatewayConstants'
import { useTheme } from '@/lib/ThemeProvider'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Copy } from 'lucide-react'
import { themes } from 'prism-react-renderer'
import { useEffect, useState } from 'react'
import { CodeBlock } from 'react-code-block'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_mainLayout/aigateway/')({
    component: () => {
        return <AIGateway key={`aigateway-overview`} />
    },
})

function AIGateway() {
    const [selectedProvider, setSelectedProvider] = useState<GatewayProvidersType>('OpenAI')
    const [providerCode, setProviderCode] = useState('')
    const [url, setUrl] = useState('')
    const [baseUrl, setBaseUrl] = useState('')
    const [universalApiKey, setUniversalApiKey] = useState(true)
    const [codeType, setCodeType] = useState<GatewayCodeType>('js')
    const { theme } = useTheme()

    const { t } = useTranslation()
    const { toast } = useToast()

    const GetAIGatewayUrl = useQuery({
        queryKey: [QueryKeys.AI_GATEWAY_URL],
        queryFn: () => getAIGateway(),
    })

    const selectBaseUrl = () => {
        switch (selectedProvider) {
            case 'Anthropic':
                setBaseUrl(`${url}/anthropic/v1/messages`)
                break
            case 'OpenAI':
                setBaseUrl(`${url}/openai/v1/chat/completions`)
                break
            case 'Ollama':
                setBaseUrl(`${url}/ollama/api/chat`)
                break
        }
    }
    const selectCode = () => {
        setProviderCode(GatewayCodeExamples[selectedProvider][codeType](url))
    }

    useEffect(() => {
        selectBaseUrl()
    }, [url])

    useEffect(() => {
        setUrl(GetAIGatewayUrl.data?.url ?? '')
        setSelectedProvider('OpenAI')
    }, [GetAIGatewayUrl.data])

    useEffect(() => {
        selectBaseUrl()
        selectCode()
    }, [selectedProvider])

    useEffect(() => {
        selectCode()
    }, [codeType])

    const copyToClipboard = (dataToCopy: string) => {
        return async () => {
            await navigator.clipboard.writeText(dataToCopy)
            toast({
                title: 'Copy to clipboard',
                description: 'Code copied to clipboard',
            })
        }
    }

    const selectCodeType = (cType: 'python' | 'js') => {
        setCodeType(cType)
    }

    return (
        <div className="flex flex-col gap-12 grow pl-4">
            <div className="flex flex-row gap-12 grow">
                <div className="w-28">
                    <img src={GatewayAI} />
                </div>
                <div className="flex flex-col w-fit justify-between gap-4">
                    <h1 className="text-black font-helvetica text-xl leading-normal dark:text-white">
                        {t('ai_gateway.title')}
                    </h1>
                    <div className="flex flex-col gap-4 grow w-full max-w-xl">
                        {t('ai_gateway.description')}
                        <br />
                        <span className={'flex-1 text-primary hover:underline'}>
                            <a href="/library" target="_blank" rel="noreferrer">
                                {t('ai_gateway.link_text')}
                            </a>
                        </span>
                    </div>
                    <Card
                        className="bg-background bg-primary/20 text-black "
                        style={{ minWidth: '768px', maxWidth: '940px' }}
                    >
                        <CardContent>
                            <div className="flex flex-col justify-between gap-4 pt-4">
                                <div className="flex flex-col gap-2 grow">
                                    <div className="flex flex-row gap-4 my-2">
                                        <div className="flex flex-col gap-2 w-72">
                                            <p className="text-sm font-medium dark:text-white">
                                                <b>{t('ai_gateway.provider')}</b>
                                            </p>
                                            <div className="flex flex-row gap-4 my-2 w-64">
                                                <Select
                                                    onValueChange={(val) => {
                                                        setSelectedProvider(
                                                            val as GatewayProvidersType
                                                        )
                                                    }}
                                                    defaultValue={'OpenAI'}
                                                >
                                                    <SelectTrigger className="bg-background dark:text-white">
                                                        <SelectValue
                                                            placeholder={t(
                                                                'ai_gateway.select_provider'
                                                            )}
                                                            defaultValue={'OpenAI'}
                                                        />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>
                                                                {t('ai_gateway.select_provider')}
                                                            </SelectLabel>
                                                            {GatewayProviders.map((p) => (
                                                                <SelectItem key={p} value={p}>
                                                                    {p}
                                                                </SelectItem>
                                                            ))}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2 min-w-[520px]">
                                            <p className="text-sm font-medium dark:text-white">
                                                <b>{t('ai_gateway.base_url')}</b>
                                            </p>
                                            <div className="flex flex-row gap-4 my-2 items-start">
                                                <Input
                                                    value={baseUrl}
                                                    name="base_url"
                                                    placeholder="Base Url"
                                                    className="w-full bg-background focus-visible:ring-0 dark:text-white"
                                                    readOnly={true}
                                                />
                                                <Button
                                                    type="button"
                                                    variant={'textOnly'}
                                                    className="text-blue-500 hover:text-blue-600 w-12 p-0"
                                                    onClick={copyToClipboard(baseUrl)}
                                                >
                                                    <Copy className="w-full" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedProvider && (
                                    <div className="flex flex-col gap-2 grow ">
                                        <CodeBlock
                                            language={codeType}
                                            code={providerCode}
                                            theme={theme == 'dark' ? themes.vsDark : themes.vsLight}
                                            words={['AIRIA_API_KEY', 'YOUR_AIRIA_GATEWAY_URL']}
                                        >
                                            <div className="relative">
                                                <CodeBlock.Code
                                                    as="div"
                                                    className="bg-background p-6 rounded-xl shadow-lg text-wrap whitespace-pre-wrap break-all border-slate pt-16"
                                                >
                                                    <div className="table-row overflow-auto mt-10">
                                                        <CodeBlock.LineContent className="table-cell text-sm constant">
                                                            <CodeBlock.Token>
                                                                {({
                                                                    isTokenHighlighted,
                                                                    children,
                                                                }) => (
                                                                    <span
                                                                        className={
                                                                            isTokenHighlighted
                                                                                ? 'font-bold !text-red-900 dark:!text-blue-400'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        {children}
                                                                    </span>
                                                                )}
                                                            </CodeBlock.Token>
                                                        </CodeBlock.LineContent>
                                                        <p>{'\n'}</p>
                                                    </div>
                                                </CodeBlock.Code>
                                                <Tabs
                                                    className="bg-background absolute top-2 left-2 text-sm font-semibold dark:bg-background"
                                                    defaultValue={'shell'}
                                                >
                                                    <TabsList>
                                                        <TabsTrigger
                                                            value="shell"
                                                            className="hover:bg-background data-[state=active]:bg-gray-200 data-[state=active]:text-black data-[state=active]:font-bold"
                                                            onClick={() => selectCodeType('js')}
                                                        >
                                                            <span>Shell</span>
                                                        </TabsTrigger>
                                                        <TabsTrigger
                                                            value="python"
                                                            className="hover:bg-background data-[state=active]:bg-gray-200 data-[state=active]:text-black data-[state=active]:font-bold"
                                                            onClick={() => selectCodeType('python')}
                                                        >
                                                            <span>Python</span>
                                                        </TabsTrigger>
                                                    </TabsList>
                                                </Tabs>
                                                <Button
                                                    type="button"
                                                    variant="textOnly"
                                                    className="text-blue-500 hover:text-blue-600 w-12 p-0 absolute top-2 right-2"
                                                    onClick={copyToClipboard(providerCode)}
                                                >
                                                    <Copy className="w-12" />
                                                </Button>
                                            </div>
                                        </CodeBlock>
                                    </div>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div className="flex flex-row gap-12 grow">
                <div className="w-28">
                    <img src={GatewayPlane} />
                </div>
                <div className="flex flex-col justify-between gap-4 w-fit max-w-xl">
                    <div className="flex flex-row items-center gap-4">
                        <h1 className="text-black font-helvetica text-xl leading-normal dark:text-white">
                            {t('ai_gateway.use_airia_title')}
                        </h1>
                        <Switch
                            id="universal_key"
                            checked={universalApiKey}
                            disabled={true}
                            onCheckedChange={(checked) => {
                                setUniversalApiKey(!!checked)
                            }}
                        />
                    </div>
                    <div className="flex flex-col gap-4 grow">
                        <p>{t('ai_gateway.use_airia_description1')}</p>
                        <p>{t('ai_gateway.use_airia_description2')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
