import type { FeedResult } from '@/api/feed'
import { getProxyReportConversation } from '@/api/gatewayUsage.ts'
import { getAllProjects } from '@/api/projects'
import AIChip from '@/assets/icons/ai-chip.svg?react'
import Airia from '@/assets/models/Airia.svg?react'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon'
import { Badge } from '@/components/ui/badge'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { QueryKeys } from '@/constants/QueryKeys'
import { cn } from '@/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { themes } from 'prism-react-renderer'
import type React from 'react'
import { useEffect, useRef } from 'react'
import { CodeBlock } from 'react-code-block'
import GatewayIcon from '@/assets/icons/log-out.svg?react'

interface FeedModalProps {
    isOpen: boolean
    onClose: () => void
    callDetails: FeedResult
    projectId?: string
}

const GatewayModal: React.FC<FeedModalProps> = ({ isOpen, onClose, callDetails, projectId }) => {
    const chatEndRef = useRef<HTMLDivElement>(null)

    const allProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })

    const chatHistoryQuery = useQuery({
        queryKey: ['feed-details', callDetails.id],
        queryFn: () => getProxyReportConversation(callDetails.id),
    })

    const date = new Date(callDetails.createdAt)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setTimeout(() => {
            if (chatEndRef.current) {
                chatEndRef.current.scrollIntoView({ behavior: 'instant' })
            }
        }, 50)
    }, [isOpen])

    const renderDuration = (callDuration: string) => {
        const parts = callDuration.split(':')
        const hours = Number.parseInt(parts[0], 10)
        const minutes = Number.parseInt(parts[1], 10)
        const seconds = Number.parseFloat(parts[2])
        const totalSeconds = hours * 3600 + minutes * 60 + seconds
        const duration = `${totalSeconds.toFixed(2)} seconds`

        return <p className="text-[15px] text-gray-550">{duration}</p>
    }

    const renderSelectedProject = (projectId: string | undefined) => {
        const selectedProject = allProjectsQuery.data?.find((project) => project.id === projectId)
        return selectedProject ? (
            <div className={'flex gap-6 items-center'}>
                {selectedProject.projectIcon ? (
                    <img
                        alt="project"
                        src={selectedProject.projectIcon}
                        className={'h-12 w-12 mr-1'}
                    />
                ) : (
                    <Airia className={'h-12 w-12'} />
                )}
                <p className="font-gilroy-medium text-4xl text-gray-550">{selectedProject.name}</p>
            </div>
        ) : (
            <div className={'flex gap-6 items-center'}>
                <AIChip className={'h-12 w-12'} />
                <p className="font-gilroy-medium text-4xl text-gray-550">AI Gateway</p>
            </div>
        )
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-modal-lg max-h-[1000px]">
                <DialogHeader>
                    <DialogTitle>Call details</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between">
                        {renderSelectedProject(projectId)}
                        <div className="text-sm text-gray-550 text-right">
                            <div>Client IP: {callDetails.clientIpAddress}</div>
                            <div>User Agent: {callDetails.userAgent}</div>
                            <div>Prompt Tokens: {callDetails.promptTokens}</div>
                            <div>Completion Tokens: {callDetails.completionTokens}</div>
                        </div>
                    </div>

                    <table className="w-full text-sm">
                        <thead>
                            <tr className="text-left text-gray-500">
                                <th>Timestamp</th>
                                <th>Model</th>
                                <th>Source</th>
                                <th>API Key</th>
                                <th>Response Time</th>
                                <th>Total Tokens</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="flex flex-col">
                                        <p>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</p>
                                    </div>
                                </td>
                                <td className="py-2">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-col gap-[3px]">
                                            <div className="flex flex-row items-center">
                                                <ProviderIcon
                                                    provider={callDetails.providerType}
                                                    className="w-6 h-6 mr-2 -ml-2"
                                                />
                                                <p className="text-gray-550">
                                                    {callDetails.providerType}
                                                </p>
                                            </div>
                                            <p className="text-[15px] text-gray-450 ml-6 max-w-32 text-ellipsis line-clamp-1">
                                                {callDetails.requestModel}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col text-gray-550">
                                        <div className="flex flex-row gap-2 items-center">
                                            <GatewayIcon className="w-4 h-4" />
                                            <p className="text-gray-550">{callDetails.source}</p>
                                        </div>
                                        {callDetails.source === 'Pipeline' && (
                                            <p className="text-gray-450 ml-[25px]">{`${callDetails.pipelineExecutionName} - v${callDetails.pipelineVersionNumber}`}</p>
                                        )}
                                        {callDetails.source === 'Playground' &&
                                            callDetails.pipelineExecutionName && (
                                                <p className="text-gray-450 ml-[25px]">
                                                    {callDetails.pipelineExecutionName}
                                                </p>
                                            )}
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        <p className="text-gray-550">
                                            {callDetails.apiKeyName !== null &&
                                            callDetails.apiKeyName !== ''
                                                ? callDetails.apiKeyName
                                                : 'AiriaKey'}
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div className="flex flex-col">
                                        {renderDuration(callDetails.duration)}
                                    </div>
                                </td>
                                <td>
                                    <p className="text-[15px] text-gray-550">
                                        {callDetails.totalTokens} tokens
                                    </p>
                                </td>
                                <td>
                                    {callDetails.streamed && (
                                        <div className="flex gap-2 flex-wrap max-w-[300px] items-center content-center align-middle m-auto">
                                            <Badge className="rounded-[24px] text-sm px-3 py-[2px] w-fit text-gray-550 bg-primary-100 hover:bg-primary-100 hover:text-gray-550 justify-center">
                                                Streamed
                                            </Badge>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-4">
                        <h3 className="font-semibold mb-2">Chat History</h3>
                        <div className="space-y-4 h-[400px] overflow-y-auto px-4">
                            {chatHistoryQuery.data?.map((message, index) => (
                                <div
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    key={index}
                                    className={cn(
                                        'p-4 rounded-lg',
                                        message.role === 'system' || message.role === 'assistant'
                                            ? 'bg-blue-50'
                                            : 'bg-green-50'
                                    )}
                                >
                                    <div className="font-semibold">
                                        {message.role === 'system' || message.role === 'assistant'
                                            ? 'Response'
                                            : 'Request'}
                                    </div>
                                    <CodeBlock
                                        language="js"
                                        code={message.message}
                                        theme={themes.vsLight}
                                    >
                                        <CodeBlock.Code
                                            as="div"
                                            className="mt-1 p-2 rounded-xl bg-inherit text-wrap whitespace-pre-wrap break-all overflow-y-scroll"
                                        >
                                            <div className="table-row overflow-auto">
                                                <CodeBlock.LineContent className="table-cell text-sm">
                                                    <CodeBlock.Token />
                                                </CodeBlock.LineContent>
                                            </div>
                                        </CodeBlock.Code>
                                    </CodeBlock>
                                    <div className="text-xs text-gray-500 mt-2">
                                        {message.createdAt}
                                    </div>
                                </div>
                            ))}
                            <div ref={chatEndRef} />
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default GatewayModal
