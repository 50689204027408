import { getAllPipelines } from '@/api/pipelinesConfig'
import { getAllProjects } from '@/api/projects'
import { QueryKeys } from '@/constants/QueryKeys'
import { HandleAuthenticatedError } from '@/lib/HandleErrors'
import { useTour } from '@reactour/tour'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useEffect, useState } from 'react'

interface OnboardingState {
    enabled: boolean
    selector: boolean
}

export function useOnboarding() {
    const [onboarding, setOnboarding] = useState<OnboardingState>({
        enabled: false,
        selector: false,
    })
    const { setIsOpen, setCurrentStep, currentStep } = useTour()
    const navigate = useNavigate()

    const AllProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: async () => await getAllProjects().catch((e) => HandleAuthenticatedError(e)),
    })

    const PipelinesQuery = useQuery({
        queryKey: [QueryKeys.ALL_PIPELINES, AllProjectsQuery.data?.[0].id],
        queryFn: () => getAllPipelines({ projectId: AllProjectsQuery.data?.[0].id ?? '' }),
        enabled: AllProjectsQuery.isSuccess,
    })

    const handleOnboardingSelection = (selection: string) => {
        if (selection === 'new') {
            setOnboarding((prev) => ({ ...prev, enabled: true, selector: false }))
            localStorage.setItem(
                'platform-onboarding',
                JSON.stringify({ ...onboarding, enabled: true, selector: false })
            )
        } else {
            setOnboarding((prev) => ({ ...prev, selector: false }))
            localStorage.setItem(
                'platform-onboarding',
                JSON.stringify({ ...onboarding, selector: false })
            )
        }
    }

    const checkIfNewUser = () => {
        if (AllProjectsQuery.isSuccess && PipelinesQuery.isSuccess) {
            return (
                PipelinesQuery.data &&
                PipelinesQuery.data.items.length === 1 &&
                PipelinesQuery.data.items[0].name === 'Sample - Ask me anything'
            )
        }
    }

    useEffect(() => {
        const storedOnboarding = localStorage.getItem('platform-onboarding')
        if (AllProjectsQuery.isSuccess && PipelinesQuery.isSuccess) {
            if (storedOnboarding == null) {
                if (checkIfNewUser()) {
                    setOnboarding((prev) => ({ ...prev, selector: true }))
                    localStorage.setItem(
                        'platform-onboarding',
                        JSON.stringify({ ...onboarding, selector: true })
                    )
                } else {
                    setOnboarding((prev) => ({ ...prev, selector: false }))
                    localStorage.setItem(
                        'platform-onboarding',
                        JSON.stringify({ ...onboarding, selector: false })
                    )
                }
            } else {
                setOnboarding(JSON.parse(storedOnboarding))
            }
        }
    }, [
        AllProjectsQuery.data,
        PipelinesQuery.data,
        AllProjectsQuery.isSuccess,
        PipelinesQuery.isSuccess,
    ])

    useEffect(() => {
        const pathName = window.location.pathname
        if (
            PipelinesQuery.isSuccess &&
            onboarding.enabled &&
            !pathName.includes('login') &&
            !pathName.includes('legal')
        ) {
            setTimeout(() => {
                setIsOpen(true)
                setCurrentStep(0)
            }, 500)
            navigate({ to: '/' })
        }
    }, [onboarding, navigate, setIsOpen, setCurrentStep, PipelinesQuery.isSuccess])

    useEffect(() => {
        if (onboarding.enabled) {
            localStorage.setItem(
                'platform-onboarding',
                JSON.stringify({ ...onboarding, enabled: currentStep !== 8 })
            )
        }
    }, [currentStep, onboarding])

    return {
        onboarding,
        handleOnboardingSelection,
    }
}
