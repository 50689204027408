import ChatLogo from '@/assets/system_chat.png'
import { useAuth } from '@/hooks/use-auth'
import { CircleUser } from 'lucide-react'
import { HelpCenterChatMessageProps } from '../types'

const dateFormatter = Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
})

export const HelpCenterChatMessage = (props: HelpCenterChatMessageProps) => {
    const { user: loggedInUser } = useAuth()

    const { type, message, createdAt } = props
    return (
        <div className="flex flex-row p-2 gap-4">
            <div className="flex flex-col w-[30px]">
                {type == 'user' && <CircleUser className="w-full" />}
                {type == 'system' && <img src={ChatLogo} className="w-full p-1" />}
            </div>
            <div className="flex flex-col justify-start w-[300px]">
                <p className="font-semibold">
                    {type == 'system' ? 'Airia Support' : loggedInUser?.firstName}
                </p>
                <p className="text-justify">{message}</p>
                <p className="text-sm text-gray-300">{dateFormatter.format(createdAt)}</p>
            </div>
        </div>
    )
}
