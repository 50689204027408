import React from 'react'
import { updateTenant } from '@/api/workspaces'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface WorkspaceEditForm {
    accessControlGroup: boolean
}

interface WorkspaceEditModalProps {
    isOpen: boolean
    onClose: () => void
    tenant: {
        id: string
        name: string
        accessControlGroupId: string | null
    }
}

export const WorkspaceEditModal: React.FC<WorkspaceEditModalProps> = ({
    isOpen,
    onClose,
    tenant,
}) => {
    const queryClient = useQueryClient()
    const { t } = useTranslation()
    const { handleSubmit, getValues, formState, reset, control } = useForm<WorkspaceEditForm>({})

    const tenantMutation = useMutation({
        mutationFn: async (createAccessControl: boolean) => {
            await updateTenant(tenant.id, createAccessControl)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ORGANIZATIONS] })
            toast({
                title: 'Workspace updated',
                description: 'Workspace updated successfully',
                variant: 'default',
            })
            reset()
            onClose()
        },
        onError: () => {
            toast({
                title: 'Workspace update failed',
                description: 'Workspace update did not complete successfully',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = async () => {
        try {
            const accessControlGroupValue = getValues('accessControlGroup')
            await tenantMutation.mutateAsync(accessControlGroupValue)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className={'max-w-modal-md max-h-modal px-4 py-3'}>
                <DialogTitle>Edit workspace</DialogTitle>
                <div className="grid grid-cols-4">
                    <div></div>
                    <div className={'col-span-4'}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={'flex flex-col gap-4'}>
                                <div>
                                    <Label htmlFor="name">{t('name')}</Label>
                                    <Input id="name" disabled value={tenant?.name} />
                                </div>
                                <Label htmlFor="accessControlGroup" className="mr-2">
                                    {t('workspaces.access_control')}
                                </Label>
                                <Controller
                                    name="accessControlGroup"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            id="accessControlGroup"
                                            disabled={tenant?.accessControlGroupId != null}
                                            checked={
                                                tenant?.accessControlGroupId != null || field.value
                                            }
                                            onCheckedChange={field.onChange}
                                        />
                                    )}
                                />
                            </div>
                            <Button
                                className={'mt-4'}
                                disabled={!formState.isValid || !formState.isDirty}
                                type={'submit'}
                            >
                                Update
                            </Button>
                        </form>
                    </div>
                    <div></div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
