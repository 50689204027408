import { getUserProfile } from '@/api/profile'
import { Project, getAllProjects } from '@/api/projects'
import { User, getUserById } from '@/api/users'
import { QueryKeys } from '@/constants/QueryKeys'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Router, useRouter } from '@tanstack/react-router'
import React, { createContext, useContext, ReactNode, useCallback } from 'react'

interface Profile {
    id: string
    givenName: string
    email: string
    subject: string
    groups?: string[]
}

interface UserProfile extends User {
    profile: Profile
}

interface AuthContextType {
    isAuthenticated: boolean
    isPlatformUser: boolean
    isLegalAdmin: boolean
    user: UserProfile | null
    isLoading: boolean
    error: Error | null
    checkAndRedirect: (condition: boolean, redirectPath?: string) => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: { children: ReactNode }) {
    const auth = useAuthHandler()

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function useAuth(): AuthContextType {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

function useAuthHandler(): AuthContextType {
    const router = useRouter()
    const profileQuery: UseQueryResult<Profile, Error> = useQuery({
        queryFn: getUserProfile,
        queryKey: [QueryKeys.USER_PROFILE],
        retry: 0,
    })
    const userQuery = useQuery({
        queryFn: () => getUserById(profileQuery.data?.subject ?? ''),
        queryKey: [QueryKeys.LOGGED_IN_USER, profileQuery.data?.id],
        enabled: profileQuery.isSuccess,
        retry: 0,
    })
    const projectsQuery = useQuery({
        queryFn: () => getAllProjects(),
        queryKey: [QueryKeys.PROJECTS, profileQuery.data?.id],
        enabled: profileQuery.isSuccess,
        retry: 0,
    })

    const isAuthenticated = profileQuery.isSuccess && !!profileQuery.data
    const isPlatformUser =
        (isAuthenticated && profileQuery.data?.groups?.includes('/Platform Editor')) || false
    const isLegalAdmin =
        (isAuthenticated && profileQuery.data?.groups?.includes('/Legal Admin')) ||
        isPlatformUser ||
        false

    useRedirectEffect(profileQuery, router, isPlatformUser, projectsQuery.data || [])

    const checkAndRedirect = useCallback(
        (condition: boolean, redirectPath: string = '../') => {
            if (!condition) {
                router.navigate({ to: redirectPath })
            }
        },
        [router]
    )
    const user =
        userQuery.data && userQuery.data.id && profileQuery.data
            ? ({ ...userQuery.data, profile: profileQuery.data } as UserProfile)
            : null

    return {
        isAuthenticated,
        isPlatformUser,
        isLegalAdmin,
        user: user,
        isLoading: profileQuery.isLoading,
        error: profileQuery.error,
        checkAndRedirect,
    }
}

function useRedirectEffect(
    profileQuery: UseQueryResult<Profile, Error>,
    router: Router,
    isPlatformUser: boolean,
    projects: Project[]
) {
    React.useEffect(() => {
        // Check if the current URL contains the word "legal"
        const currentPath = location.pathname.toLowerCase()
        if (currentPath.includes('legal')) return

        if (!profileQuery.data) return
        const isLegalUser = profileQuery.data.groups?.some(
            (group) => group.includes('Legal Admin') || group.includes('Legal User')
        )

        if (!isPlatformUser && isLegalUser) {
            const legalProject = projects.find((project) => project.projectType === 'Legal')

            if (legalProject) {
                router.navigate({
                    to: '/$projectId/legal',
                    params: { projectId: legalProject.id },
                })
            } else {
                console.warn('No legal project found')
            }
        }
    }, [profileQuery, router, isPlatformUser, projects, location.pathname])
}

export type { AuthContextType, UserProfile }
