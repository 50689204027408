// fr.ts

export const frenchTranslation = {
    home: 'Maison',
    pipelines: 'Pipelines',
    models: 'Modèles',
    datasets: 'Ensembles de données',
    prompts: 'Prompts',
    api_keys: 'Clés API',
    memory: 'Mémoire',
    administration: 'Administration',
    language: 'Langue',
    english: 'Anglais',
    spanish: 'Espagnol',
    italian: 'Italien',
    german: 'Allemand',
    french: 'Français',
    portuguese: 'Portugais',
    logout: 'Déconnexion',
    toggle_theme: 'Changer de thème',
    error_occurred: "Une erreur s'est produite",
    airia_platform: 'Plateforme Airia',
    login: 'Connexion',
    uptime: 'Temps de fonctionnement',
    costs_per_month: 'Coûts / mois',
    prompts_per_month: 'Prompts / mois',
    at: 'à',
    add_pipeline_step: 'Ajouter une étape de pipeline',
    objects: 'Objets',
    model: 'Modèle',
    dataset: 'Ensemble de données',
    actions: 'Actions',
    pipeline: 'Pipeline',
    code_execution: 'Exécution de code',
    recall_memory: 'Rappeler la mémoire',
    store_memory: 'Stocker la mémoire',
    loading: 'Chargement',
    max_number_of_results: 'Nombre max de résultats',
    relevance_percentage: '% Pertinence',
    input: 'Entrée',
    load: 'Charger',
    store: 'Stocker',
    search: 'Rechercher',
    select_a_type: 'Sélectionner un type',
    temperature: 'Température',
    select_prompt: 'Sélectionner un prompt...',
    search_prompts: 'Rechercher des prompts...',
    no_model_found: 'Aucun modèle trouvé.',
    node_info: 'Informations sur le nœud',
    delete: 'Supprimer',
    name: 'Nom',
    guid: 'GUID',
    step_guid: "GUID de l'étape",
    api_url: "URL de l'API",
    api_key: 'Clé API',
    output: 'Sortie',
    version: 'Version',
    error_title: 'Oh non! Quelque chose a mal tourné.',
    error_description: "Une erreur s'est produite lors de la création du pipeline.",
    pipeline_name: 'Nom du pipeline',
    name_validation:
        'Le nom ne peut contenir que des lettres, des chiffres, des espaces et les caractères - ~ . _',
    other: 'Autre',
    toggle: 'Basculer',
    playground: 'Terrain de jeu',
    try_out_pipeline: 'Essayer votre pipeline',
    request: 'Requête',
    ask_anything: "Demandez n'importe quoi",
    save_as: 'Enregistrer sous',
    save: 'Enregistrer',
    unique_pipeline_name: 'Le nouveau nom de pipeline doit être unique.',
    save_new_pipeline: 'Enregistrer le nouveau pipeline',
    create_new_pipeline: 'Cela créera un nouveau pipeline avec la configuration actuelle.',
    new_pipeline_name: 'Nom du nouveau pipeline',
    python_code_execution: 'Exécution de code Python',
    view_api_key: 'Voir la clé API',
    created_date: 'Date de création',
    error_description_api: "Une erreur s'est produite lors de la suppression de la clé API.",
    open_menu: 'Ouvrir le menu',
    see_api_key: 'Voir la clé API',
    delete_api_key: 'Supprimer la clé API',
    new_api_key: 'Nouvelle clé API',
    create_new_api_key: 'Créer une nouvelle clé API',
    key: 'Clé',
    create: 'Créer',
    data_source: 'Source de données',
    select_data_source: 'Sélectionner une source de données',
    azure_blob_storage: 'Azure Blob Storage',
    container_name: 'Nom du conteneur',
    database: 'Base de données',
    select_database: 'Sélectionner une base de données',
    pinecone_api_key: 'Clé API Pinecone',
    pinecone_index_name: "Nom de l'index Pinecone",
    connection_string: 'Chaîne de connexion',
    embedding_model: "Modèle d'intégration",
    select_embedding_model: "Sélectionner un modèle d'intégration",
    chunking_strategy: 'Stratégie de découpage',
    select_chunking_strategy: 'Sélectionner une stratégie de découpage',
    airia_magic: "Magie d'Airia",
    custom: 'Personnalisé',
    markdown: 'Markdown',
    recursive: 'Récursif',
    html: 'HTML',
    token: 'Jeton',
    character: 'Caractère',
    chunk_size: 'Taille du segment',
    chunk_overlap: 'Chevauchement du segment',
    separator: 'Séparateur',
    encoding_name: "Nom de l'encodage",
    reprocess: 'Retravailler',
    update: 'Mettre à jour',
    loginPage: {
        header: 'Accélérez votre parcours IA',
        enterEmail: 'Entrez votre Email',
        continue: 'Continuer',
        newToAiria: 'Nouveau sur Airia ?',
        getStarted: 'Commencer',
        troubleLoggingIn: 'Des difficultés pour vous connecter ?',
    },
    error_description_model: "Une erreur s'est produite lors de la mise à jour du modèle.",
    columns: {
        name: 'Nom',
        executionName: "Nom d'exécution",
        status: 'Statut',
        lastUpdated: 'Dernière mise à jour',
    },
    actions_data_source: {
        openMenu: 'Ouvrir le menu',
        label: 'Actions',
        edit: 'Modifier la source de données',
        delete: 'Supprimer la source de données',
    },
    data_source_title: 'Sources de données',
    newDataSource: 'Nouvelle source de données',
    createDataset: {
        title: 'Créer un nouveau dataset',
        dataSource: 'Source de données',
        selectDataSource: 'Sélectionner une source de données',
        name: 'Nom',
        namePatternTitle:
            'Le nom ne peut contenir que des lettres, des chiffres, des espaces et les caractères - ~ . _',
        containerName: 'Nom du conteneur',
        database: 'Base de données',
        selectDatabase: 'Sélectionner une base de données',
        pineconeApiKey: 'Clé API Pinecone',
        pineconeIndexName: "Nom de l'index Pinecone",
        connectionString: 'Chaîne de connexion',
        embeddingModel: "Modèle d'intégration",
        selectEmbeddingModel: "Sélectionner un modèle d'intégration",
        chunkingStrategy: 'Stratégie de découpage',
        selectChunkingStrategy: 'Sélectionner une stratégie de découpage',
        airiaMagic: "Magie d'Airia",
        custom: 'Personnalisé',
        markdown: 'Markdown',
        recursive: 'Récursif',
        html: 'HTML',
        token: 'Jeton',
        character: 'Caractère',
        chunkSize: 'Taille du segment',
        chunkOverlap: 'Chevauchement du segment',
        separator: 'Séparateur',
        encodingName: "Nom de l'encodage",
        create: 'Créer',
    },
    editMemory: {
        success_title: 'Succès!',
        success_description_prompt: 'Mémoire mise à jour avec succès.',
        error_title: 'Oh non! Quelque chose a mal tourné.',
        error_description: "Une erreur s'est produite lors de la mise à jour de la mémoire.",
        duplicateNameError: 'Une mémoire avec le même nom existe déjà dans cette project.',
        name: 'Nom',
        type: 'Type',
        selectType: 'Sélectionner un type',
        submit: 'Soumettre',
        types: {
            user: 'Utilisateur',
            conversation: 'Conversation',
        },
    },
    editModel: {
        successTitle: 'Succès!',
        successDescription: 'Modèle mise à jour avec succès.',
        errorTitle: 'Oh non! Quelque chose a mal tourné.',
        errorDescription: "Une erreur s'est produite lors de la mise à jour du modèle.",
        displayName: "Nom d'affichage",
        provider: 'Fournisseur de modèle',
        selectProvider: 'Sélectionner un fournisseur de modèle',
        endpoint: 'Point de terminaison du modèle',
        apiKey: 'Clé API',
        modelName: 'Nom du modèle',
        modelInput: 'Entrée du modèle',
        selectType: "Sélectionner un type d'entrée du modèle",
        systemPrompt: 'Invite système',
        selectSystemPrompt: 'Sélectionner une invite système',
        searchPrompts: 'Rechercher des prompts...',
        noModelFound: 'Aucun modèle trouvé.',
        submit: 'Soumettre',
        types: {
            text: 'Texte',
            image: 'Image',
        },
    },
    memoryIndex: {
        memory: 'Mémoire',
        newMemory: 'Nouvelle mémoire',
        openMenu: 'Ouvrir le menu',
        actions: 'Actions',
        editMemory: 'Modifier la mémoire',
        deleteMemory: 'Supprimer la mémoire',
    },
    newMemory: {
        name: 'Nom',
        type: 'Type',
        selectType: 'Sélectionner un type',
        submit: 'Soumettre',
        types: {
            user: 'Utilisateur',
            conversation: 'Conversation',
        },
        duplicateNameError: 'Une mémoire avec le même nom existe déjà dans cette project.',
        generalError: "Une erreur s'est produite lors de la création de la mémoire.",
    },
    newModel: {
        displayName: "Nom d'affichage",
        provider: 'Fournisseur de modèle',
        selectProvider: 'Sélectionner un fournisseur de modèle',
        endpoint: 'Point de terminaison du modèle',
        apiKey: 'Clé API',
        modelName: 'Nom du modèle',
        modelInput: 'Entrée du modèle',
        selectType: "Sélectionner un type d'entrée du modèle",
        systemPrompt: 'Invite système',
        selectSystemPrompt: 'Sélectionner une invite système',
        searchPrompts: 'Rechercher des prompts...',
        noModelFound: 'Aucun modèle trouvé.',
        submit: 'Soumettre',
        types: {
            text: 'Texte',
            image: 'Image',
        },
    },
    columns_models: {
        name: 'Nom',
        executionName: "Nom d'exécution",
        lastUpdated: 'Dernière mise à jour',
    },
    actions_models: {
        openMenu: 'Ouvrir le menu',
        label: 'Actions',
        edit: 'Modifier le modèle',
        delete: 'Supprimer le modèle',
    },
    create_new_model: 'Créer un nouveau modèle',
    text: 'Texte',
    image: 'Image',
    save_changes: 'Enregistrer les modifications',
    edit: 'Modifier',
    success_title: 'Succès!',
    pipeline_updated_successfully: 'Pipeline mis à jour avec succès.',
    error_description_pipeline: "Une erreur s'est produite lors de la mise à jour du pipeline.",
    columns_pipelines: {
        name: 'Nom',
        status: 'Statut',
        version: 'Version',
        modified: 'Modifié',
        stats: 'Statistiques',
    },
    actions_pipelines: {
        label: 'Actions',
        edit: 'Modifier le pipeline',
        connection_info: 'Informations de connexion',
        delete: 'Supprimer le pipeline',
    },
    sort_by: 'Trier par',
    date_modified: 'Date de modification',
    new_pipeline: 'Nouveau pipeline',
    status_ready: 'Prêt',
    success_description_prompt: 'Prompt mis à jour avec succès.',
    error_description_prompt: "Une erreur s'est produite lors de la mise à jour du prompt.",
    select_version: 'Sélectionner la version',
    active: 'Actif',
    set_active: 'Définir comme actif',
    description: 'Description',
    messages: 'Messages',
    add_message: 'Ajouter un message',
    new_prompt: 'Nouveau prompt',
    active_version: 'Version active',
    latest_version: 'Dernière version',
    last_modified: 'Dernière modification',
    unknown_date: 'Date inconnue',
    edit_prompt: 'Modifier le prompt',
    delete_prompt: 'Supprimer le prompt',
    delete_prompt_confirmation: 'Êtes-vous sûr de vouloir supprimer ce prompt?',
    delete_prompt_error: 'Échec de la suppression du prompt:',
    error_description_create_prompt: 'Échec de la création du prompt:',
    project: 'Project',
    projects: 'Projects',
    applicaton_description: 'Créez et affichez des projects.',
    project_create: 'Créer une candidature',
    project_view: 'Voir la candidature',
    project_delete: "Supprimer l'project",
    back: 'Retour',
    groups: 'Groupes',
    users: 'Utilisateurs',
    permissions: 'Autorisations',
    data_sources: 'Les sources de données',
    workbench: 'Établi',
    exit: 'Quitter',
    add_component: 'Ajouter un Composant',
    type_your_message: 'Tapez votre message...',
    prompt_versions: 'Versions de Prompt',
    select_a_model: 'Sélectionner un Modèle',
    select_a_prompt: 'Sélectionner un Prompt',
    select_a_prompt_version: 'Sélectionner une Version de Prompt',
    remove_chat_component: 'Supprimer le Composant de Chat',
    clear_files: 'Effacer les Fichiers',
    upload_files: 'Télécharger des Fichiers',
    send_message: 'Envoyer le Message',
    page_size: 'Taille de la Page',
    workspaces: {
        actions: {
            open_menu: 'Ouvrir le menu',
            access_control: "Contrôle d'accès",
        },
        workspace: 'Espace de travail',
        workspaces: 'Espaces de travail',
        workspaces_description: 'Créer et voir les espaces de travail.',
        workspace_create: 'Créer un espace de travail',
        access_control: "Contrôle d'accès",
        workspace_create_success: 'Espace de travail créé avec succès.',
        select_workspace: 'Sélectionner un espace de travail',
        switching_workspace_header: 'Presque là !',
        switching_workspace_message: "Changement d'espace de travail, veuillez patienter.",
        workspace_switched_header: 'Fait !',
        workspace_switched_message: "Prêt à accéder à l'espace de travail.",
        access_new_workspace: "Accéder à l'espace de travail",
        error_description: "Une erreur s'est produite lors de la sélection de l'espace de travail.",
    },
} as const
