import { uploadImage } from '@/api/attachments'
import { Project, createProject, deleteProject, getAllProjects } from '@/api/projects'
import { Button } from '@/components/ui/button'
import { CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Toaster } from '@/components/ui/toaster'
import { toast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { Eye, LucideIcon, MoreHorizontal, Trash, Upload } from 'lucide-react'
import React, { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DefaultDataTable } from '../DefaultDataTable'

interface NewAppForm {
    name: string
    description: string
    projectIconId: string
}

const Projects: React.FC = () => {
    const queryClient = useQueryClient()
    const AllProjectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })
    const { t } = useTranslation()

    const handleDelete = async (row: Project) => {
        await deleteProject(row.id).catch((error) => {
            console.log(error)
            toast({
                title: 'Error',
                description: error.message,
                variant: 'destructive',
            })
        })
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.PROJECTS] })
    }

    const navigate = useNavigate()

    const columns: ColumnDef<Project>[] = [
        {
            accessorKey: 'icon',
            header: '',
            cell: ({ row }) => {
                return row.original.projectIcon ? (
                    <img
                        src={row.original.projectIcon}
                        alt={t('imgAlt.projectIcon')}
                        className=" w-16 h-16 rounded-md object-cover"
                    />
                ) : (
                    ''
                )
            },
        },
        {
            accessorKey: 'name',
            header: t('name'),
        },
        {
            accessorKey: 'groups',
            header: t('groups'),
            cell: () => {
                return <p>--</p>
            },
        },
        {
            accessorKey: 'createdAt',
            header: t('created_date'),
            cell: ({ row }) => {
                return <p>{new Date(row.original.createdAt).toLocaleString()}</p>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 ">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem
                                onClick={() => {
                                    navigate({
                                        to: '/projects/$projectId/edit',
                                        params: { projectId: row.original.id },
                                    })
                                }}
                            >
                                <div className="flex flex-row gap-1">
                                    <Eye className="h-4 w-4" />
                                    {t('project_view')}
                                </div>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="flex gap-1 text-red-500"
                                onClick={() => handleDelete(row.original)}
                            >
                                <Trash className="h-4 w-4" />
                                {t('project_delete')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    return (
        <>
            {AllProjectsQuery.data && (
                <div className="min-h-[80vh] mr-3 bg-background">
                    <CardHeader>
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col gap-2 grow">
                                <CardTitle>{t('projects')}</CardTitle>
                                <CardDescription>{t('applicaton_description')}</CardDescription>
                            </div>
                            <CreateNewProject variant="default" />
                        </div>
                    </CardHeader>
                    <CardContent>
                        <DefaultDataTable
                            onRowClick={(row: any) => {
                                navigate({
                                    to: '/projects/$projectId/edit',
                                    params: { projectId: row.original.id },
                                })
                            }}
                            data={AllProjectsQuery.data}
                            columns={columns}
                        />
                    </CardContent>
                </div>
            )}
            <Toaster />
        </>
    )
}

export const CreateNewProject = ({
    variant,
    onSuccess,
    size,
    Icon,
}: {
    variant:
        | 'default'
        | 'destructive'
        | 'outline'
        | 'secondary'
        | 'ghost'
        | 'link'
        | null
        | undefined
    size?: 'default' | 'sm' | 'lg' | 'icon'
    Icon?: LucideIcon
    onSuccess?: () => void
}) => {
    const queryClient = useQueryClient()
    const [openCreate, setOpenCreate] = useState(false)
    const appForm = useForm<NewAppForm>()
    const { t } = useTranslation()
    const [fileToUpload, setFileToUpload] = useState<{ file: File; previewUrl: string }>()

    const handleAddIcon = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files)
            const newItems = filesArray.map((file) => ({
                file,
                previewUrl: URL.createObjectURL(file),
            }))
            setFileToUpload(newItems[0])
            const res = await uploadImage({ file: newItems[0].file })
            appForm.setValue('projectIconId', res.id)
        }
    }

    const handleCreateProject = async (data: NewAppForm) => {
        try {
            //@ts-ignore
            await createProject({
                ...data,
                pipelines: [],
                models: [],
                dataSources: [],
                prompts: [],
                apiKeys: [],
                memories: [],
                credentials: [],
            })

            await queryClient.invalidateQueries({ queryKey: [QueryKeys.PROJECTS] })
            setOpenCreate(false)
            onSuccess && onSuccess()
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <div>
            <Button type="button" size={size} onClick={() => setOpenCreate(true)} variant={variant}>
                {Icon ? <Icon className="text-muted-foreground h-5 w-5" /> : t('project_create')}
            </Button>
            <Dialog open={openCreate} onOpenChange={setOpenCreate}>
                <form onSubmit={appForm.handleSubmit(handleCreateProject)}>
                    <DialogContent className="max-w-modal-sm max-h-modal">
                        <DialogHeader>
                            <DialogTitle>Create new project</DialogTitle>
                        </DialogHeader>
                        <div className="grid gap-4 py-4 w-full">
                            <div className="grid grid-cols-4 items-center gap-4">
                                <div className="flex flex-col gap-2 min-w-72 w-full">
                                    <Label htmlFor="name" className="text-left">
                                        Name:
                                    </Label>
                                    <Input
                                        id="name"
                                        type="text"
                                        className="col-span-3"
                                        {...appForm.register('name', { required: true })}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 w-72">
                                <Label htmlFor="description" className="text-left">
                                    Description:
                                </Label>
                                <textarea
                                    id="description"
                                    className="col-span-3 bg-background border border-gray-300"
                                    {...appForm.register('description')}
                                />
                            </div>
                            <div>
                                <h3 className="text-lg">Icon: </h3>
                                <div className="flex flex-row gap-2 items-center">
                                    {fileToUpload?.previewUrl ? (
                                        <img
                                            src={fileToUpload?.previewUrl}
                                            alt={t('imgAlt.projectIcon')}
                                            className=" w-16 h-16 rounded-md object-cover"
                                        />
                                    ) : (
                                        <div className="w-16 h-16 bg-gray-500 rounded-md"></div>
                                    )}
                                    <input
                                        type="file"
                                        id="appIconUpload"
                                        style={{ display: 'none' }}
                                        onChange={handleAddIcon}
                                    />

                                    <Button
                                        variant="ghost"
                                        onClick={() =>
                                            document?.getElementById('appIconUpload')?.click()
                                        }
                                        type="button"
                                    >
                                        <Upload />
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                type="submit"
                                onClick={appForm.handleSubmit(handleCreateProject)}
                            >
                                Create
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}
export default Projects
