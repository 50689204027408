import {
    AzureBlobConfiguration,
    CloudKitConfiguration,
    DataSourceType,
    ScaleFlexConfiguration,
    StorageConfiguration,
} from '@/api/datasets'
import React from 'react' // Import from wherever your components are
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { MaskedInput } from '../ui/MaskedInput'
import { Checkbox } from '../ui/checkbox'
import { Input } from '../ui/input'

export interface DataSourceFormField {
    label: string
    name: string
    type: 'text' | 'masked-text' | 'number' | 'file' | 'checkbox'
    required: boolean
    group?: string
}

export interface DataSourceForm {
    fields: DataSourceFormField[]
    source: string
}

export type DataSourceFormBuilder = {
    [key: string]: DataSourceForm
}

export type GroupedFields =
    | { [key: string]: DataSourceFormField[] }
    | { [key: string]: DataSourceFormField[]; ungrouped: DataSourceFormField[] }

export const getDataSourceConfig = (type: DataSourceType): StorageConfiguration => {
    switch (type) {
        case DataSourceType.AZURE_BLOB:
            return {
                type: 'AzureBlob',
                defaultFilePattern: '',
                connectionString: '',
                containerName: '',
            } as AzureBlobConfiguration
        case DataSourceType.SCALE_FLEX:
            return {
                type: 'ScaleFlex',
                defaultFilePattern: '',
                token: '',
                apiKey: '',
            } as ScaleFlexConfiguration
        case DataSourceType.CLOUD_KIT:
            return {
                type: 'CloudKit',
                defaultFilePattern: '',
                isEcKeyFilePath: false,
            } as CloudKitConfiguration
        case DataSourceType.FILE_UPLOAD:
            return { type: 'FileUpload', defaultFilePattern: '' } as StorageConfiguration
        default:
            throw new Error(`Unsupported data source type: ${type}`)
    }
}

interface FormFieldProps {
    field: DataSourceFormField
    register: UseFormRegister<any>
    setValue: UseFormSetValue<any>
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => void
}

export const FormField: React.FC<FormFieldProps> = ({
    field,
    register,
    setValue,
    handleFileChange,
}) => {
    switch (field.type) {
        case 'file':
            return (
                <Input
                    id={field.name}
                    type="file"
                    onChange={(e) => handleFileChange(e, field.name)}
                    required={field.required}
                />
            )
        case 'masked-text':
            return (
                <MaskedInput
                    id={field.name}
                    maskAll={true}
                    setValue={setValue}
                    type="text"
                    fieldName={`configuration.${field.name}`}
                    {...register(`configuration.${field.name}` as any, {
                        required: field.required,
                    })}
                />
            )
        case 'checkbox':
            return (
                <Checkbox
                    id={field.name}
                    {...register(`configuration.${field.name}` as any, {
                        required: field.required,
                    })}
                />
            )
        default:
            return (
                <Input
                    id={field.name}
                    type={field.type}
                    {...register(`configuration.${field.name}` as any, {
                        required: field.required,
                    })}
                />
            )
    }
}
