// es.ts

export const spanishTranslation = {
    home: 'Hogar',
    pipelines: 'Canales',
    models: 'Modelos',
    model_library: 'Librería de Modelos',
    datasets: 'Conjuntos de Datos',
    prompts: 'Indicaciones',
    api_keys: 'Claves API',
    memory: 'Memoria',
    credentials: 'Credenciales',
    administration: 'Administración',
    language: 'Idioma',
    english: 'Inglés',
    spanish: 'Español',
    italian: 'Italiano',
    portuguese: 'Portugués',
    french: 'Francés',
    german: 'Alemán',
    logout: 'Cerrar sesión',
    toggle_theme: 'Cambiar tema',
    error_occurred: 'Ocurrió un error',
    airia_platform: 'Plataforma Airia',
    login: 'Iniciar sesión',
    uptime: 'Tiempo de actividad',
    costs_per_month: 'Costos / mes',
    prompts_per_month: 'Indicaciones / mes',
    at: 'a las',
    from: 'desde',
    by: 'por',
    add_pipeline_step: 'Agregar Paso de Canal',
    objects: 'Objetos',
    model: 'Modelo',
    dataset: 'Conjunto de Datos',
    actions: 'Acciones',
    pipeline: 'Canal',
    code_execution: 'Ejecución de Código',
    recall_memory: 'Recuperar Memoria',
    store_memory: 'Almacenar Memoria',
    loading: 'Cargando',
    max_number_of_results: 'Máx # de Resultados',
    relevance_percentage: '% Relevancia',
    input: 'Entrada',
    load: 'Cargar',
    store: 'Almacenar',
    search: 'Buscar',
    select_a_type: 'Seleccione un tipo',
    temperature: 'Temperatura',
    select_prompt: 'Seleccione una indicación...',
    search_prompts: 'Buscar Indicación...',
    no_model_found: 'No se encontró el modelo.',
    node_info: 'Información del Nodo',
    delete: 'Eliminar',
    name: 'Nombre',
    guid: 'GUID',
    step_guid: 'GUID del Paso',
    api_url: 'URL de la API',
    api_key: 'Clave API',
    copy_key: 'Copiar Clave ',
    key_copied: 'Clave copiada!',
    copy_url: 'Copiar URL',
    url_copied: 'URL copiada!',
    copy_id: 'Copiar ID',
    id_copied: 'ID copiado!',
    output: 'Salida',
    version: 'Versión',
    error_title: '¡Oh no! Algo salió mal.',
    error_description: 'Algo salió mal al intentar crear el Canal.',
    pipeline_name: 'Nombre del Canal',
    name_validation:
        'El nombre solo puede contener letras, dígitos, espacios y los caracteres - ~ . _',
    other: 'Otro',
    toggle: 'Alternar',
    playground: 'Entorno de pruebas',
    try_out_pipeline: 'Pruebe su Canal',
    request: 'Solicitud',
    ask_anything: 'Preguntar cualquier cosa',
    save_as: 'Guardar como',
    save: 'Guardar',
    imgAlt: {
        airiaLogo: ' Logo Airia',
        screenshot: 'Captura de Pantalla',
        preview: 'Vista previa',
        messageImg: 'Imagen de mensaje',
        projectIcon: 'Icono de proyecto',
    },
    unique_pipeline_name: 'El nuevo nombre del Canal debe ser único.',
    save_new_pipeline: 'Guardar Canal',
    create_new_pipeline: 'Esto creará un Canal con la configuración actual.',
    new_pipeline_name: 'Nuevo Nombre de Canal',
    python_code_execution: 'Ejecución de Código Python',
    view_api_key: 'Ver Clave API',
    created_date: 'Fecha de Creación',
    error_description_api: 'Algo salió mal al intentar eliminar la clave API.',
    open_menu: 'Abrir menú',
    see_api_key: 'Ver Clave API',
    delete_api_key: 'Eliminar Clave API',
    new_api_key: 'Nueva Clave API',
    create_new_api_key: 'Crear Nueva Clave API',
    api_key_new_success: 'Clave API creado exitosamente.',
    api_key_deleted: 'Clave API eliminado exitosamente.',
    key: 'Clave',
    create: 'Crear',
    data_source: 'Fuente de Datos',
    select_data_source: 'Seleccione una fuente de datos',
    azure_blob_storage: 'Azure Blob Storage',
    container_name: 'Nombre del Contenedor',
    database: 'Base de Datos',
    select_database: 'Seleccione una Base de Datos',
    pinecone_api_key: 'Clave API de Pinecone',
    pinecone_index_name: 'Nombre del Índice de Pinecone',
    connection_string: 'Cadena de Conexión',
    embedding_model: 'Modelo de Embedding',
    select_embedding_model: 'Seleccione un Modelo de Embedding',
    chunking_strategy: 'Estrategia de Segmentación',
    select_chunking_strategy: 'Seleccione una Estrategia de Segmentación',
    airia_magic: 'Magia de Airia',
    custom: 'Personalizado',
    markdown: 'Markdown',
    recursive: 'Recursivo',
    html: 'HTML',
    token: 'Token',
    character: 'Carácter',
    chunk_size: 'Tamaño del Segmento',
    chunk_overlap: 'Superposición del Segmento',
    separator: 'Separador',
    encoding_name: 'Nombre de Codificación',
    reprocess: 'Reprocesar',
    update: 'Actualizar',
    loginPage: {
        header: 'Acelera tu viaje hacia la IA',
        enterEmail: 'Ingrese su correo electrónico',
        continue: 'Continuar',
        newToAiria: '¿Nuevo en Airia?',
        getStarted: 'Comenzar',
        orGetStarted: 'o comienza con',
    },
    error_description_model: 'Algo salió mal al intentar actualizar el modelo.',
    columns: {
        name: 'Nombre',
        executionName: 'Nombre de Ejecución',
        status: 'Estado',
        lastUpdated: 'Última Actualización',
    },
    actions_data_source: {
        openMenu: 'Abrir menú',
        label: 'Acciones',
        edit: 'Editar Fuente de Datos',
        delete: 'Eliminar Fuente de Datos',
    },
    data_source_title: 'Fuentes de Datos',
    newDataSource: 'Nueva Fuente de Datos',
    createDataset: {
        title: 'Crear Nuevo Conjunto de Datos',
        dataSource: 'Fuente de Datos',
        selectDataSource: 'Seleccione una fuente de datos',
        name: 'Nombre',
        namePatternTitle:
            'El nombre solo puede contener letras, dígitos, espacios y los caracteres - ~ . _',
        containerName: 'Nombre del Contenedor',
        database: 'Base de Datos',
        selectDatabase: 'Seleccione una Base de Datos',
        pineconeApiKey: 'Clave API de Pinecone',
        pineconeIndexName: 'Nombre del Índice de Pinecone',
        connectionString: 'Cadena de Conexión',
        embeddingModel: 'Modelo de Embedding',
        selectEmbeddingModel: 'Seleccione un Modelo de Embedding',
        chunkingStrategy: 'Estrategia de Segmentación',
        selectChunkingStrategy: 'Seleccione una Estrategia de Segmentación',
        airiaMagic: 'Magia de Airia',
        custom: 'Personalizado',
        markdown: 'Markdown',
        recursive: 'Recursivo',
        html: 'HTML',
        token: 'Token',
        character: 'Carácter',
        chunkSize: 'Tamaño del Segmento',
        chunkOverlap: 'Superposición del Segmento',
        separator: 'Separador',
        encodingName: 'Nombre de Codificación',
        create: 'Crear',
        success_description: 'Fuente de datos creada con éxito.',
        userSpecific: 'Fuente de datos específica del usuario',
    },
    editMemory: {
        successTitle: '¡Éxito!',
        successDescription: 'Memoria actualizada con éxito.',
        error_title: '¡Oh no! Algo salió mal.',
        error_description: 'Algo salió mal al intentar actualizar la memoria.',
        duplicateNameError: 'Ya existe una memoria con el mismo nombre en esta aplicación.',
        name: 'Nombre',
        type: 'Tipo',
        selectType: 'Seleccione un tipo',
        submit: 'Enviar',
        types: {
            user: 'Usuario',
            conversation: 'Conversación',
        },
    },
    editModel: {
        successTitle: '¡Éxito!',
        successDescription: 'Modelo actualizada con éxito.',
        errorTitle: '¡Oh no! Algo salió mal.',
        errorDescription: 'Algo salió mal al intentar actualizar el modelo.',
        displayName: 'Nombre para Mostrar',
        provider: 'Proveedor del Modelo',
        selectProvider: 'Seleccione un proveedor de modelo',
        endpoint: 'Endpoint del Modelo',
        apiKey: 'Clave API',
        modelName: 'Nombre del Modelo',
        modelInput: 'Entrada del Modelo',
        selectType: 'Seleccione un tipo de entrada del modelo',
        systemPrompt: 'Indicación del Sistema',
        selectSystemPrompt: 'Seleccione una indicación del sistema',
        searchPrompts: 'Buscar Indicación...',
        noModelFound: 'No se encontró el modelo.',
        submit: 'Enviar',
        types: {
            text: 'Texto',
            image: 'Imagen',
        },
    },
    memoryIndex: {
        memory: 'Memoria',
        newMemory: 'Nueva Memoria',
        openMenu: 'Abrir menú',
        actions: 'Acciones',
        editMemory: 'Editar Memoria',
        deleteMemory: 'Eliminar Memoria',
    },
    newMemory: {
        name: 'Nombre',
        type: 'Tipo',
        selectType: 'Seleccione un tipo',
        submit: 'Enviar',
        types: {
            user: 'Usuario',
            conversation: 'Conversación',
        },
        duplicateNameError: 'Ya existe una memoria con el mismo nombre en esta aplicación.',
        generalError: 'Ocurrió un error al crear la memoria.',
        typeRequired: 'El tipo de memoria es requerido.',
        success_description: 'Memoria creada exitosamente.',
    },
    newModel: {
        displayName: 'Nombre para Mostrar',
        provider: 'Proveedor del Modelo',
        selectProvider: 'Seleccione un proveedor de modelo',
        endpoint: 'Endpoint del Modelo',
        apiKey: 'Clave API',
        modelName: 'Nombre del Modelo',
        modelInput: 'Entrada del Modelo',
        selectType: 'Seleccione un tipo de entrada del modelo',
        systemPrompt: 'Indicación del Sistema',
        selectSystemPrompt: 'Seleccione una indicación del sistema',
        searchPrompts: 'Buscar Indicaciones...',
        noModelFound: 'No se encontró el modelo.',
        submit: 'Enviar',
        types: {
            text: 'Texto',
            image: 'Imagen',
        },
        successDescription: 'Modelo creado exitosamente.',
        errorDescription: 'Error al crear el modelo',
    },
    columns_models: {
        name: 'Nombre',
        executionName: 'Nombre de Ejecución',
        lastUpdated: 'Última Actualización',
    },
    actions_models: {
        openMenu: 'Abrir menú',
        label: 'Acciones',
        edit: 'Editar Modelo',
        delete: 'Eliminar Modelo',
    },
    configure_model: 'Configurar modelo',
    create_new_model: 'Crear Nuevo Modelo',
    text: 'Texto',
    image: 'Imagen',
    save_changes: 'Guardar cambios',
    edit: 'Editar',
    hide: 'Ocultar',
    success_title: '¡Éxito!',
    pipeline_created_successfully: 'Canal creado con éxito.',
    pipeline_updated_successfully: 'Canal actualizado con éxito.',
    error_description_pipeline: 'Algo salió mal al intentar actualizar el canal.',
    columns_pipelines: {
        name: 'Nombre',
        status: 'Estado',
        version: 'Versión',
        modified: 'Modificado',
        stats: 'Estadísticas',
        executions: 'Ejecuciones',
        tokens_used: 'Tokens Usados',
    },
    actions_pipelines: {
        label: 'Acciones',
        edit: 'Editar Canal',
        connection_info: 'Información de Conexión',
        delete: 'Eliminar Canal',
        export_error: 'Algo salió mal al intentar exportar el canal.',
    },
    sort_by: 'Ordenar por',
    new_pipeline: 'Nuevo Canal',
    status_ready: 'Listo',
    status_not_ready: 'No está listo',
    success_description_prompt: 'Indicación actualizada con éxito.',
    error_description_prompt: 'Algo salió mal al intentar actualizar la indicación.',
    select_version: 'Seleccione la Versión',
    active: 'Activo',
    set_active: 'Establecer como Activo',
    description: 'Descripción',
    messages: 'Mensajes',
    add_message: 'Agregar Mensaje',
    prompt: 'Indicación',
    new_prompt: 'Nueva Indicación',
    active_version: 'Versión Activa',
    latest_version: 'Última Versión',
    last_modified: 'Última Modificación',
    unknown_date: 'Fecha Desconocida',
    edit_prompt: 'Editar Indicación',
    delete_prompt: 'Eliminar Indicación',
    delete_prompt_confirmation: '¿Está seguro de que desea eliminar esta indicación?',
    delete_prompt_error: 'Error al eliminar la indicación:',
    delete_prompt_success: 'Indicación eliminada con éxito.',
    error_description_create_prompt: 'Error al crear la indicación:',
    project: 'Aplicación',
    projects: 'Aplicaciones',
    applicaton_description: 'Crear y ver aplicaciones.',
    project_create: 'Crear aplicación',
    project_view: 'Ver aplicación',
    project_edit: 'Editar aplicación',
    project_delete: 'Eliminar aplicación',
    back: 'Atrás',
    groups: 'Grupos',
    users: 'Usuarios',
    permissions: 'Permisos',
    data_sources: 'Fuentes de datos',
    workbench: 'Banco de trabajo',
    executions: 'Ejecuciones',
    tokens_used: 'Tokens Usados',
    exit: 'Salir',
    add_component: 'Agregar módulos de pruebas',
    type_your_message: 'Escribe tu mensaje...',
    prompt_versions: 'Versiones de Indicación',
    select_a_model: 'Seleccionar un Modelo',
    select_a_prompt: 'Seleccionar una indicación',
    select_a_prompt_version: 'Seleccionar una Versión de Indicación',
    remove_chat_component: 'Eliminar Componente de Chat',
    clear_files: 'Borrar Archivos',
    upload_files: 'Subir Archivos',
    send_message: 'Enviar Mensaje',
    save_prompt: 'Guardar Indicacion',
    prompt_output: 'Output',
    remove: 'Eliminar',
    reset: 'Reiniciar',
    credits: {
        threshold_type: 'Tipo de límite',
        available_credits: 'Créditos disponibles',
        manage_credits: 'Administrar créditos',
        available: 'crédito(s) disponible(s)',
        invoicing: 'Facturas',
        pools: 'Grupos',
        view_all: 'Ver todo',
        no_pools: 'No hay grupos todavía',
        no_pools_subheader: 'Una vez creados, tus Grupos aparecerán aquí.',
        subheader: {
            get_started: 'Empezar por ',
            purchasing: 'Compra de créditos',
            apply_towards: ' Para aplicar a sus grupos ',
        },
        create_pool: 'Crear grupo',
        manage_pool: 'Administrar grupo',
        delete_pool: 'Eliminar grupo',
        save_pool: 'Guardar grupo',
        new_pool: 'Nuevo nombre de grupo',
        allotted_credits: 'Créditos asignados',
        assigned_projects: 'Proyectos asignados',
        create_success_description: 'Grupo creado con éxito.',
        update_success_description: 'Grupo actualizado con éxito.',
        update_error_assigned: 'El proyecto ya está asignado a otro grupo.',
        delete_success_description: 'Grupo eliminado con éxito.',
    },
    credits_activity: {
        project: 'Proyecto',
        inout: 'In/Out',
        credits: 'Créditos',
        credits_available: 'Créditos disponibles',
        speed: 'Velocidad',
        timestamp: 'Fecha y hora',
        back: 'Volver a Créditos',
        token_usage: 'Uso de token',
        pool_balance: 'Balance del grupo',
        usage: 'Uso',
        performance: 'Rendimiento',
    },
    filter: 'Filtrar',
    marketplace: {
        banner_header: 'Explorar Soluciones Existentes de IA',
        banner_subheader:
            'Explora más de 1,000 poderosos modelos de IA, construidos por creadores con experiencia',
        gen_text: 'Texto Generativo',
        object_detection: 'Detección de Objetos',
        image_to_text: 'Imagen a Texto',
        browse_all_models: 'Explora todos los modelos',
        sorted_by: 'Organizado por',
        downloads: 'Descargas',
        likes: 'Me gusta',
        search_models: 'Buscar Modelos',
        type: 'Tipo',
        model_name: 'Nombre del Modelo',
        provider: 'Proveedor',
        source: 'Fuente',
        all_models: 'Todos los modelos',
        chatbot: 'Chatbot',
        speech: 'Hablar',
        image: 'Imagen',
        most_popular_chatbots: 'Chatbots más populares',
        see_all: 'Ver todos',
        vision: 'Visión',
        people_using: 'Personas usando este modelo',
        price_starting: 'Precio desde',
        price: 'Precio',
        free: 'Gratis',
        select: 'Seleccionar',
        select_option: 'Selecciona una Opción',
        select_project: 'Selecciona una Aplicación',
    },
    home_page: {
        get_started: 'Comencemos',
        create_pipeline: 'Crea tu primer canal',
        create_pipeline_desc: 'Utiliza la interfaz para diseñar y personalizar tu canal de IA.',
        integrate_data: 'Integra tus datos',
        integrate_data_desc:
            'Conecta tus fuentes de datos para impulsar tus canales sin ningún esfuerzo.',
        explore_templates: 'Explorar plantillas',
        explore_templates_desc:
            'Inicia tus proyectos usando plantillas pre-hechas diseñadas por varios proyectos de IA.',
        browse_community: 'Explorar la Comunidad',
        browse_community_desc:
            'Encuentra modelos, fragmentos de código y más para integrarlos a tus canales',
        quick_actions: 'Acciones rápidas',
        action_create_pipeline: 'Crear Canal',
        action_add_model: 'Crear Modelo',
        action_more_info: 'Más Info',
        action_add_data_source: 'Agregar Fuente de Datos',
        action_add_prompt: 'Agregar Indicación',
        no_traffic: 'Sin tráfico todavía',
        no_traffic_desc: 'Una vez el canal esta activo, puedes monitorear el rendimiento aquí',
        no_activity: 'No hay actividad reciente',
        no_activity_desc:
            'Cuando comiences a configurar los canales, aquí podrás ver tus actividades recientes para rápido acceso.',
    },
    edit_data_source: 'Editar Fuente de Datos',
    edit_data_source_success: 'Fuente de datos actualizada con éxito.',
    delete_data_source: 'Eliminar Fuente de Datos',
    delete_data_source_success: 'Fuente de datos eliminada con éxito.',
    modelName: 'Nombre del Modelo',
    export: 'Exportar',
    data_connector: {
        add_connector_heading: 'Selecciona un conector de documento',
        add_connector_subheading: 'Proyectos y herramientas para llevar tus documentos en Airia',
        details: 'Detalles',
    },
    terms_and_privacy: {
        title: 'Términos y privacidad',
        terms_of_service: 'Términos de servicio',
        privacy_policy: 'Política de privacidad',
        first_section: 'Al hacer clic en "Aceptar", aceptas los',
    },
    error_description_generic: 'Algo salió mal. Inténtalo de nuevo más tarde.',
    accept: 'Aceptar',
    cancel: 'Cancelar',
    and: 'y',
    new_credentials: {
        create_new_credentials: 'Crear nuevas credenciales',
        new_credentials: 'Nuevas credenciales',
        name: 'Nombre',
        type: 'Tipo',
        select_type: 'Seleccionar un tipo',
        data: 'Datos de credenciales',
        success_description: 'Credenciales creadas con éxito.',
    },
    edit_credentials: {
        edit: 'Editar credenciales',
        see_credentials: 'Ver credenciales',
        delete_credentials: 'Eliminar credenciales',
        success_description: 'Credenciales actualizadas con éxito.',
        delete_success_description: 'Credenciales eliminadas con éxito.',
    },
    feedback: {
        send_feedback: 'enviar comentarios',
        help_us: 'Ayúdanos a mejorar esta página.',
    },
    download_report: 'Descargar reporte',
    reports: 'Reportes',
    billing: 'Facturación',
    upgrades: 'Actualizaciones',
    tools: {
        title: 'Herramientas',
        new_tool: 'Nueva herramienta',
        edit_tool: 'Editar herramienta',
        tool_handler: 'Administrador de herramientas',
        parameters: 'Parámetros',
    },
    side_nav_headers: {
        project: 'PROYECTO',
        libraries: 'LIBRERIAS',
        account: 'CUENTA',
        available_models: 'Modelos disponibles',
    },
    workspaces: {
        actions: {
            open_menu: 'Abrir menú',
            access_control: 'Control de acceso',
        },
        workspace: 'Espacio de trabajo',
        workspaces: 'Espacios de trabajo',
        workspaces_description: 'Crear y ver espacios de trabajo.',
        workspace_create: 'Crear espacio de trabajo',
        access_control: 'Control de acceso',
        workspace_create_success: 'Espacio de trabajo creado con éxito.',
        select_workspace: 'Seleccionar espacio de trabajo',
        switching_workspace_header: '¡Ya casi!',
        switching_workspace_message: 'Cambiando de espacio de trabajo, por favor espera.',
        workspace_switched_header: '¡Hecho!',
        workspace_switched_message: 'Listo para acceder al espacio de trabajo.',
        access_new_workspace: 'Acceder al espacio de trabajo',
        error_description: 'Algo salió mal al intentar cambiar de espacio de trabajo.',
    },
    pipeline_builder_options: {
        save_changes: 'Guardar cambios',
        pipeline: 'Opciones de canal',
    },
    pipeline_version: 'Versión del canal',
    view_logs: 'Ver registros',
    no_data_available: 'No hay datos disponibles',
    version_change_description:
        'Agregue una breve descripción del cambio realizado en la indicación.',
    version_change_description_label: 'Actualizar descripción',
    file_upload_success: 'Archivo actualizado con éxito.',
    debug_information: 'Ver Información',
}
