import { DataSourceFormBuilder } from '@/components/newDataSource/dataSourceFormBuilder'
import apiRequest from '@/lib/apiClient'

export interface StorageConfiguration {
    type: string
    defaultFilePattern: string
}

// AzureBlobConfiguration type
export interface AzureBlobConfiguration extends StorageConfiguration {
    connectionString: string
    containerName: string
}

export interface ScaleFlexConfiguration extends StorageConfiguration {
    token: string
    apiKey: string
}

export interface CloudKitConfiguration extends StorageConfiguration {
    container: string
    environment: string
    recordType: string
    database: string
    s2sKeyId: string
    ecKeyData: string
    isEcKeyFilePath: boolean
}

export interface BaseDataset {
    id: string
    name: string
    executionName: string
    state: string
    createdAt: string
    updatedAt: string
    chunkingConfigId: string
    projectId: string
    embeddingProvider: string
    dataSourceType: string
    isUserSpecific: boolean
    dataSourceGroupId: string
}

interface BaseChunkingStrategy {
    chunkSize: number
    chunkOverlap: number
    strategyType: string
}
type CharacterChunkingStrategy = BaseChunkingStrategy & {
    separator: string
}

type RecursiveChunkingStrategy = BaseChunkingStrategy

type HTMLChunkingstrategy = BaseChunkingStrategy

type MarkdownChunkingStrategy = BaseChunkingStrategy

type CodeChunkingStrategy = BaseChunkingStrategy & {
    code: string
}

type TokenChunkingStrategy = BaseChunkingStrategy & {
    encodingName: string
}

export type ChunkingStrategy =
    | CharacterChunkingStrategy
    | RecursiveChunkingStrategy
    | HTMLChunkingstrategy
    | MarkdownChunkingStrategy
    | CodeChunkingStrategy
    | TokenChunkingStrategy

export enum ChunkingStrategyEnum {
    automatic = 'automatic',
    recursive = 'recursive',
    html = 'HTML',
    markdown = 'markdown',
    code = 'code',
    token = 'token',
    character = 'character',
}

export type DataSourceConfiguration =
    | AzureBlobConfiguration
    | ScaleFlexConfiguration
    | StorageConfiguration

export type Dataset = BaseDataset & {
    databaseType: string
    pineconeApiKey: string
    pineconeIndexName: string
    chunkingConfig: ChunkingStrategy
    configuration: DataSourceConfiguration
}

export type DataSourceFileOverview = FileMetadata & {
    fileId?: string
    dataSourceId: string
    filePath: string
    processingStatus: ProcessingStatus
}

export enum DataSourceType {
    AZURE_BLOB = 'AzureBlob',
    SCALE_FLEX = 'ScaleFlex',
    CLOUD_KIT = 'CloudKit',
    FILE_UPLOAD = 'FileUpload',
    UNKNOWN = 'Unknown',
}

export type AzureBlobDataset = Dataset
export type ScaleFlexDataset = Dataset

export enum DatasourceState {
    ready = 0,
    failed = 1,
    processing = 2,
}

export enum ProcessingStatus {
    pending = 'Pending',
    processing = 'Processing',
    processed = 'Processed',
    failed = 'Failed',
}

export type FileMetadata = {
    name: string
    mimeType: string
    lastUpdated: string
    size: number
    downloadUrl: string
    processingStatus: ProcessingStatus
}

export type DataSourceList = {
    items: (Dataset & { fileCount: number })[]
    totalCount: number
}

export type DataSourceIngestionLog = {
    dataSourceName?: string
    items: DataSourceIngestionLogItem[]
}

export type DataSourceIngestionLogItem = {
    message: string
    state: string
    createdAt: string
}

export const CreateDataset = async (data: Dataset) => {
    return apiRequest('/Datasource', { method: 'POST', body: data })
}

export const UpdateDataset = async (data: Dataset) => {
    return apiRequest(`/Datasource/${data.id}`, { method: 'PUT', body: data })
}

export const GetAllDatasets = async (
    queryParams?: Record<string, string | number> | undefined
): Promise<DataSourceList> => {
    return apiRequest('/Datasource', { queryParams })
}

export const DeleteDataset = async (id: string) => {
    return apiRequest(`/Datasource/${id}`, { method: 'DELETE' })
}

export const GetDatasetById = async (id: string): Promise<Dataset> => {
    return apiRequest(`/Datasource/${id}`)
}

export const GetDatasetFilesById = async (
    id: string,
    queryParams?: Record<string, string | number>
): Promise<DataSourceFileOverview[]> => {
    const filteredParams = queryParams
        ? (Object.fromEntries(
              Object.entries(queryParams).filter(([_, v]) => v != null)
          ) satisfies Record<string, string | number>)
        : undefined

    return apiRequest(`/Datasource/${id}/files`, { queryParams: filteredParams })
}

export const CreateDataSourceFile = async (
    id: string,
    file: File,
    queryParams?: Record<string, string | number>
): Promise<DataSourceFileOverview> => {
    const formData = new FormData()
    formData.append('file', file)
    return apiRequest(`/Datasource/${id}/files`, {
        method: 'POST',
        body: formData,
        queryParams,
        isFormData: true,
    })
}

export const UpdateDataSourceFile = async (
    dataSourceId: string,
    fileId: string,
    file: File,
    queryParams?: Record<string, string | number>
): Promise<void> => {
    const formData = new FormData()
    formData.append('file', file)
    return apiRequest(`/Datasource/${dataSourceId}/files/${fileId}`, {
        method: 'PUT',
        body: formData,
        queryParams,
        isFormData: true,
    })
}

export const DeleteDataSourceFile = async (id: string, fileId: string): Promise<void> => {
    return apiRequest(`/Datasource/${id}/files/${fileId}`, { method: 'DELETE' })
}

export const getIngestionLogsById = async (id: string): Promise<DataSourceIngestionLog> => {
    return apiRequest(`/DataSourceIngestionLog/${id}`)
}

export const getDataSourceForms = async (): Promise<DataSourceFormBuilder> => {
    return apiRequest('/Datasource/forms')
}
