import { getUserProfile } from '@/api/profile.ts'
import { Button } from '@/components/ui/button.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { captureFeedback, captureMessage } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { HelpCenterProps, UserFeedbackForm } from '../types'

export const UserFeedback = (props: HelpCenterProps) => {
    const { t } = useTranslation()
    const { data: userProfile } = useQuery({
        queryKey: [QueryKeys.USER_PROFILE],
        queryFn: getUserProfile,
    })
    const [copyClicked, setCopyClicked] = useState(false)
    const { formState, handleSubmit, register, reset } = useForm<UserFeedbackForm>()

    useEffect(() => {
        if (copyClicked) {
            setTimeout(() => {
                setCopyClicked(false)
            }, 1500)
        }
    }, [copyClicked])

    const onFormSubmit = (data: UserFeedbackForm) => {
        const associatedEventId = captureMessage('User Feedback')
        captureFeedback({ ...data, email: userProfile?.email, associatedEventId })
        reset()
        props.onClose()
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                    <textarea
                        id="message"
                        placeholder={t('feedback.help_us')}
                        rows={4}
                        className={
                            'w-full focus:outline-none p-4 border-b border-border min-h-[471px] text-sm'
                        }
                        {...register('message', { required: true })}
                    ></textarea>
                </div>
                <div className={'p-2'}>
                    <Button disabled={!formState.isValid} type={'submit'}>
                        {t('feedback.send_feedback')}
                    </Button>
                </div>
            </form>
        </div>
    )
}
