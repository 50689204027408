import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { germanTranslation } from './public/locales/de/de'
import { englishTranslation } from './public/locales/en/en'
import { spanishTranslation } from './public/locales/es/es'
import { frenchTranslation } from './public/locales/fr/fr'
import { italianTranslation } from './public/locales/it/it'
import { portugueseTranslation } from './public/locales/pt/pt'

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: './public/locales/{{lng}}/{{ns}}.json',
        },
        react: {
            useSuspense: false,
        },
        resources: {
            en: {
                translation: englishTranslation,
            },
            es: {
                translation: spanishTranslation,
            },
            de: {
                translation: germanTranslation,
            },
            fr: {
                translation: frenchTranslation,
            },
            pt: {
                translation: portugueseTranslation,
            },
            it: {
                translation: italianTranslation,
            },
        },
    })

export default i18n
