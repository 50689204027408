import {
    type AiOperationsExecution,
    type ConsumptionFilterOptions,
    getAllExecutions,
} from '@/api/aiOperationsExecution.ts'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery } from '@tanstack/react-query'
import type { ColumnDef } from '@tanstack/react-table'
import type React from 'react'
import { useEffect, useState } from 'react'
import { endOfMonth, isAfter, isWithinInterval, startOfMonth, sub } from 'date-fns'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import { Link } from '@tanstack/react-router'
import PaginationHeader from '@/components/pagination/paginationHeader.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog.tsx'
import ConsumptionFilters from '@/components/home/consumption/ConsumptionFilters.tsx'
import ExportForm from '@/components/home/consumption/ExportForm.tsx'
import { Copy } from 'lucide-react'
import { toast } from '@/components/ui/use-toast.ts'
import { formatCurrency } from '@/lib/utils.ts'
import ProviderIcon from '@/components/modelLibrary/ProviderIcon.tsx'

const Consumption: React.FC = () => {
    const [filter, setFilter] = useState<string>('')
    const [consumptionFilter, setConsumptionFilter] = useState<ConsumptionFilterOptions>({})
    const [executionOperations, setExecutionOperations] = useState<AiOperationsExecution[]>([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogType, setDialogType] = useState<'export' | 'filter'>('filter')
    const [filterCount, setFilterCount] = useState<number>(0)
    const operationsQuery = useQuery({
        queryKey: [QueryKeys.ALL_OPERATIONS_EXECUTIONS],
        queryFn: () => getAllExecutions(),
    })
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    useEffect(() => {
        if (operationsQuery.data) {
            setExecutionOperations(operationsQuery.data.items)
        }
    }, [operationsQuery.data])

    useEffect(() => {
        setFilterCount(0)
        Object.keys(consumptionFilter).forEach((k) => {
            // do not add default state to filter count
            if (k === 'projectIds' && consumptionFilter.projectIds?.length === 0) {
                return
            } else if (consumptionFilter[k as keyof ConsumptionFilterOptions]) {
                setFilterCount((prev) => prev + 1)
            }
        })
    }, [consumptionFilter])

    const onCopyIconClick = (executionId: string) => {
        navigator.clipboard.writeText(executionId)
        toast({
            title: 'Copied!',
            description: 'Execution ID copied to clipboard.',
        })
    }

    const columns: ColumnDef<AiOperationsExecution>[] = [
        {
            accessorKey: 'executionDateTime',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.executionDateTime)
                return (
                    <span
                        className={
                            'whitespace-nowrap overflow-hidden max-w-[120px] text-ellipsis inline-block'
                        }
                    >{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</span>
                )
            },
        },
        {
            accessorKey: 'providerType',
            header: 'Provider',
            cell: ({ row }) => (
                <div className={'flex gap-1 items-center'}>
                    <ProviderIcon
                        provider={row.original.providerType}
                        className="w-[17px] h-[17px]"
                    />
                    {row.original.providerType}
                </div>
            ),
        },
        {
            accessorKey: 'modelName',
            header: 'Model Name',
            cell: ({ row }) => (
                <span
                    className={
                        'whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                    }
                >
                    {row.original.modelName}
                </span>
            ),
        },
        {
            accessorKey: 'executionSourceType',
            header: 'Source',
        },
        {
            accessorKey: 'pipelineId',
            header: 'Pipeline',
            cell: ({ row }) => (
                <Link
                    to={'/$projectId/pipelines/$pipelineId/$versionNumber'}
                    params={{
                        projectId: row.original.projectId,
                        pipelineId: row.original.pipelineId,
                        versionNumber: row.original.pipelineVersion,
                    }}
                    className={
                        'text-primary hover:underline whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                    }
                >
                    {row.original.pipelineName !== ''
                        ? row.original.pipelineName
                        : row.original.pipelineId}
                </Link>
            ),
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={17}
                                width={17}
                            />
                        ) : (
                            <AiriaIcon className="w-[17px] h-[17px]" />
                        )}
                        <span
                            className={
                                ' whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block'
                            }
                        >
                            {project?.name}
                        </span>
                    </div>
                )
            },
        },
        {
            header: 'Total Tokens $',
            accessorKey: 'totalTokenAmountConsumed',
            cell: ({ row }) => {
                return <span>{`$${row.original.totalTokenAmountConsumed}`}</span>
            },
        },
        {
            accessorKey: 'inputTokenAmountConsumed',
            header: 'Input Token $',
            cell: ({ row }) => {
                return <span>{`$${row.original.inputTokenAmountConsumed}`}</span>
            },
        },
        {
            accessorKey: 'outputTokenAmountConsumed',
            header: 'Output Token $',
            cell: ({ row }) => {
                return <span>{`$${row.original.inputTokenAmountConsumed}`}</span>
            },
        },
        {
            header: 'Total Tokens Consumed',
            accessorKey: 'totalTokenCountConsumed',
        },
        {
            accessorKey: 'inputTokenCountConsumed',
            header: 'Input Tokens Consumed',
        },
        {
            accessorKey: 'outputTokenCountConsumed',
            header: 'Output Tokens Consumed',
        },
        {
            accessorKey: 'balanceUsed',
            header: 'Balanced Used',
            cell: ({ row }) => {
                const displayedBalance = +row.original.balanceUsed
                return (
                    <span>
                        {isNaN(displayedBalance)
                            ? `$${row.original.balanceUsed}`
                            : formatCurrency(displayedBalance)}
                    </span>
                )
            },
        },
        {
            accessorKey: 'executionId',
            header: 'Execution ID',
            cell: ({ row }) => (
                <span className="whitespace-nowrap overflow-hidden max-w-[200px] text-ellipsis inline-block">
                    <Button
                        onClick={() => onCopyIconClick(row.original.executionId)}
                        variant={'ghost'}
                    >
                        <Copy className={'h-4 w-4'} />
                    </Button>
                </span>
            ),
        },
    ]

    const onOpenDialog = (type: 'export' | 'filter', open: boolean) => {
        setDialogType(type)
        setDialogOpen(open)
    }

    const resetActiveFilters = () => {
        setConsumptionFilter({
            projectIds: undefined,
            executionDate: undefined,
        })
        setExecutionOperations(operationsQuery.data?.items ?? [])
    }

    const onFilterChange = (filter: ConsumptionFilterOptions) => {
        resetActiveFilters()
        setConsumptionFilter(filter)
        if (filter.projectIds) {
            onProjectIdFilter(filter.projectIds)
        }
        if (filter.executionDate) {
            onExecutionDateFilter(filter.executionDate)
        }
    }
    const onProjectIdFilter = (projectIds?: string[]) => {
        if (projectIds && projectIds.length > 0) {
            setExecutionOperations(
                (prev) => prev.filter((operation) => projectIds.includes(operation.projectId)) ?? []
            )
        } else {
            setExecutionOperations(operationsQuery.data?.items ?? [])
        }
    }

    const onExecutionDateFilter = (
        executionDateStr?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
    ) => {
        if (executionDateStr === 'thisMonth') {
            const now = new Date()
            const monthStart = startOfMonth(now)
            setExecutionOperations(
                (prev) =>
                    prev.filter((operation) =>
                        isAfter(new Date(operation.executionDateTime), monthStart)
                    ) ?? []
            )
        } else if (executionDateStr === 'lastMonth') {
            const now = new Date()
            const lastMonthStart = startOfMonth(sub(now, { months: 1 }))
            const lastMonthEnd = endOfMonth(lastMonthStart)

            setExecutionOperations(
                (prev) =>
                    prev.filter((operation) =>
                        isWithinInterval(new Date(operation.executionDateTime), {
                            start: lastMonthStart,
                            end: lastMonthEnd,
                        })
                    ) ?? []
            )
        } else if (executionDateStr === 'last90Days') {
            const ninetyDaysAgo = sub(new Date(), { days: 90 })
            setExecutionOperations(
                (prev) =>
                    prev.filter((operation) =>
                        isAfter(new Date(operation.executionDateTime), ninetyDaysAgo)
                    ) ?? []
            )
        } else {
            setExecutionOperations(operationsQuery.data?.items ?? [])
        }
    }

    return (
        <div className={'flex flex-col gap-2 mt-6'}>
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2">
                    <PaginationHeader
                        filter={filter}
                        setFilter={setFilter}
                        sortOptions={columns}
                        queryParams={{
                            projectId: undefined,
                            pageNumber: 0,
                            pageSize: 0,
                            sortBy: undefined,
                            sortDirection: undefined,
                        }}
                    />
                </div>
                <div className={'flex gap-4'}>
                    <Button
                        onClick={() => onOpenDialog('filter', true)}
                        variant={filterCount > 0 ? 'default' : 'secondary'}
                        className="flex items-center gap-1"
                    >
                        Filters
                        {filterCount > 0 && (
                            <span
                                className={
                                    'h-5 w-5 flex justify-center bg-primary-foreground rounded-full text-primary px-2'
                                }
                            >
                                {filterCount}
                            </span>
                        )}
                    </Button>
                    <Button
                        onClick={() => {
                            onOpenDialog('export', true)
                        }}
                        type="button"
                        variant="secondary"
                    >
                        Export
                    </Button>
                </div>
            </div>
            <DefaultDataTable
                pagination
                totalCount={executionOperations.length}
                rowsPerPage={25}
                columns={columns}
                data={executionOperations}
            />
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogContent className={'min-w-[850px]'}>
                    <DialogHeader>
                        {dialogType === 'filter' ? (
                            <div className={'flex gap-2'}>
                                <span className={'text-2xl'}>Filters</span>
                                {filterCount > 0 && (
                                    <div
                                        className={
                                            'rounded-full bg-primary text-primary-foreground text-xs px-3 py-2'
                                        }
                                    >
                                        {filterCount}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>Export</div>
                        )}
                    </DialogHeader>
                    {dialogType === 'filter' ? (
                        <ConsumptionFilters
                            filter={consumptionFilter}
                            onFilterChange={onFilterChange}
                            onClearFilters={resetActiveFilters}
                            onClose={() => setDialogOpen(false)}
                        />
                    ) : (
                        <ExportForm currentWindow={consumptionFilter.executionDate} />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Consumption
