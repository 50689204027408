import { ApiKey, deleteApiKey, getAllApiKeys } from '@/api/apiKeys.ts'
import { getAllProjects } from '@/api/projects'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    Link,
    Outlet,
    SearchSchemaInput,
    createFileRoute,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { Eye, MoreHorizontal, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_mainLayout/apikeys')({
    component: () => {
        Route.useParams()
        return <AllApiKeys key={`apikeys-overview`} />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            pageNumber: search?.pageNumber as number,
            pageSize: search?.pageSize as number,
            sortBy: search?.sortBy as string,
        }
    },
})

function AllApiKeys() {
    const { t } = useTranslation()
    const router = useRouter()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const deleteMutation = useMutation({
        mutationFn: deleteApiKey,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_API_KEYS],
            })
            toast({
                title: t('success_title'),
                description: t('api_key_deleted'),
            })
        },
        onError: (error: { status: number; message: string }) => {
            if (error.status === 409) {
                toast({
                    title: t('error_title'),
                    description: error.message,
                    variant: 'destructive',
                })
            } else {
                // Handle unexpected error structures
                toast({
                    title: t('error_title'),
                    description: t('error_description_generic'),
                    variant: 'destructive',
                })
            }
        },
    })
    const queryParams = Route.useSearch()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        onPaginationChange,
    })

    const GetAllApiKeys = useQuery({
        queryKey: [QueryKeys.ALL_API_KEYS, queryParams],
        queryFn: () =>
            getAllApiKeys(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const GetAllProjects = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })

    const handleRowClick = (row: { original: ApiKey }) => {
        navigate({
            to: '/apikeys/$apiKeyId',
            params: { apiKeyId: row.original.id },
            search: { ...queryParams },
        })
    }

    const handleDeleteApiKey = async (apiKeyId: string) => {
        if (window.confirm(t('confirmation_to_continue'))) {
            try {
                await deleteMutation.mutateAsync(apiKeyId)
                await router.invalidate()
            } catch (error) {
                HandleDeleteError(error, 'apiKey')
            }
        }
    }

    const handleProjectName = (projectId: string) => {
        if (GetAllProjects.data && GetAllProjects.data.length > 0) {
            const project = GetAllProjects.data.find((p) => p.id === projectId)
            if (project) {
                return project.name
            }
        }
        return 'Project'
    }

    const columns: ColumnDef<ApiKey>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
        },
        {
            accessorKey: 'key',
            header: t('api_key'),
            cell: ({ row }) => {
                return <p>{`••••${row.original.key}`}</p>
            },
        },
        {
            accessorKey: 'tenantId',
            header: t('scope'),
            cell: ({ row }) => {
                const name = row.original.projectId
                    ? `Project - ${handleProjectName(row.original.projectId ?? '')}`
                    : 'Tenant'
                return <p>{name}</p>
            },
        },
        {
            accessorKey: 'createdAt',
            header: t('created_date'),
            cell: ({ row }) => {
                return <p>{new Date(row.original.createdAt).toLocaleString()}</p>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 ">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem>
                                <Link
                                    to="/apikeys/$apiKeyId"
                                    params={{
                                        apiKeyId: row.original.id,
                                    }}
                                    search={{ ...queryParams }}
                                    className="flex gap-1"
                                >
                                    <Eye className="h-4 w-4" />
                                    {t('see_api_key')}
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="flex gap-1 text-red-500"
                                onClick={() => {
                                    handleDeleteApiKey(row.original.id)
                                }}
                            >
                                <Trash className="h-4 w-4" />
                                {t('delete_api_key')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy:
                    //@ts-ignore
                    columns.find((col) => col.accessorKey === field) != null ? field : 'createdAt',
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 pr-2 pt-6">
            <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns}
                    />
                </div>
                <Link to="/apikeys/new">
                    <Button>{t('new_api_key')}</Button>
                </Link>
            </div>
            <DefaultDataTable
                data={GetAllApiKeys.data?.items ?? []}
                columns={columns}
                onRowClick={handleRowClick}
                serverPagination
                rowsPerPage={queryParams.pageSize}
                totalCount={GetAllApiKeys.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Outlet />
        </div>
    )
}
