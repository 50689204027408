import OnboardingActionIcon from '@/assets/icons/onboarding-action.svg?react'
import OnboardingMapIcon from '@/assets/icons/onboarding-map.svg?react'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import type { StepType } from '@reactour/tour'
import type { Dispatch, SetStateAction } from 'react'

export const ONBOARDING_STYLES = {
    //@ts-ignore
    popover: (base) => ({
        ...base,
        borderRadius: '8px',
        padding: '16px',
        backgroundColor: 'hsl(var(--background))',
    }),
    //@ts-ignore
    maskArea: (base) => ({
        ...base,
        rx: '4px',
    }),
}

export const steps: StepType[] = [
    {
        selector: '.first-step',
        content: `Welcome to Airia!\nStart by creating a project for the generative AI application you are building.\nSelect the default project we've created for you here.`,
        position: 'right',
    },
    {
        selector: '.tabs-step',
        content:
            'First, let me orient you\nA project can contain one or many objects that can be referenced in a pipeline to perform a specific task. We’ve added a model and a data source to help you get started.',
        position: 'right',
    },
    {
        selector: '.second-step',
        content:
            'Select a Pipeline\nA pipeline is a workflow that orchestrates data flow into and output from a model. Think of it like making a cake. The pipeline would be the steps from gathering the ingredients, mixing them together, baking the cake, and finally, serving it. Select the “Sample - Ask me anything” pipeline.',
        position: 'right',
    },
    {
        selector: '.third-step',
        content:
            'Welcome to the Pipeline Builder\nThis space is a low code orchestration canvas for building custom pipelines using models, data sources, and more. You can drag and drop objects from the left into your pipeline.',
        position: 'center',
    },
    {
        selector: '.fourth-step',
        content:
            'Using Data Sources\nYou can connect a data source to your pipeline so your model can reference an authoritative knowledge base outside of its training data sources before generating a response. We’ve added Airia’s marketing fact sheet and API reference to this data source.',
        position: 'top',
    },
    {
        selector: '.fifth-step',
        content:
            'Using AI Models\nYou can add your own models or choose from the many models in our Model Library. We’ve added OpenAI’s GPT3.5 model here.',
        position: 'top',
    },
    {
        selector: '.sixth-step',
        content: 'Test Your Pipeline\nJump into the Playground to test your pipeline set up.',
        position: 'top',
    },
    {
        selector: '.seventh-step',
        content:
            'The Playground\nYou can test your pipeline’s output here in the playground. Try asking ‘What challenges can Airia help me solve?’ Or ‘What Airia API can I call to upload a document to a data source?’. Once you are satisfied that your pipeline is functioning properly, you can go back to the pipeline builder to save your pipeline.',
        position: 'top',
    },
]

export const handleClose = ({ setIsOpen }: { setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
    setIsOpen(false)
    const onboarding = JSON.parse(localStorage.getItem('platform-onboarding') ?? '{}')
    localStorage.setItem('platform-onboarding', JSON.stringify({ ...onboarding, enabled: false }))
}

//@ts-ignore
export const CustomContent = ({ content }) => {
    const split = content.split('\n')
    return (
        <div className="flex flex-col gap-2 bg-background">
            <p className="font-bold">{split[0]}</p>
            {split.map((text: string, index: number) => index !== 0 && <p key={index}>{text}</p>)}
        </div>
    )
}

export const renderPreviousButton = ({
    currentStep,
    setCurrentStep,
}: {
    currentStep: number
    setCurrentStep: Dispatch<SetStateAction<number>>
}) => {
    if (currentStep === 0 || currentStep === 2 || currentStep === 3 || currentStep === 7) {
        return <div className="w-14 h-10 " />
    }
    return (
        <Button
            onClick={() => {
                setCurrentStep((prev) => prev - 1)
            }}
            variant="secondary"
        >
            Back
        </Button>
    )
}

export const renderNextButton = ({
    currentStep,
    setCurrentStep,
}: {
    currentStep: number
    setCurrentStep: Dispatch<SetStateAction<number>>
}) => {
    if (currentStep === 0 || currentStep === 2 || currentStep === 6 || currentStep === 7) {
        return <div className="w-14 h-10 " />
    }
    return (
        <Button
            onClick={() => {
                setCurrentStep((prev) => prev + 1)
            }}
            variant="secondary"
        >
            Next
        </Button>
    )
}

interface OnboardingSelectDialogProps {
    open: boolean
    onSelectionChange: (selection: OnboardingSelection) => void
}

type OnboardingSelection = 'new' | 'experienced' | 'skip'

export const OnboardingSelectDialog: React.FC<OnboardingSelectDialogProps> = ({
    open,
    onSelectionChange,
}) => {
    const handleSelection = (selection: OnboardingSelection) => {
        onSelectionChange(selection)
    }

    return (
        <Dialog open={open}>
            <DialogContent
                hideCloseButton
                className="max-h-modal max-w-modal-lg bg-transparent border-none flex flex-col gap-10"
            >
                <p className="text-center text-pretty max-w-[662px] self-center text-7xl font-gilroy-medium text-white">
                    How do you want to get started?
                </p>
                <div className="grid grid-cols-2 gap-10 justify-center evenly">
                    <SelectionCard
                        icon={<OnboardingMapIcon className="self-center w-[100px] h-[100px]" />}
                        text="I'm new, show me around and help me get started."
                        onClick={() => handleSelection('new')}
                        className="bg-[radial-gradient(240.03%_134.39%_at_98.66%_96.88%,rgba(224,204,250,0.50)_0%,rgba(255,255,255,0.50)_43.45%,rgba(183,240,255,0.50)_100%),linear-gradient(194deg,#CAF2FF_18.92%,#ACACFF_90.74%)]"
                        textColor="text-primary"
                    />
                    <SelectionCard
                        icon={<OnboardingActionIcon className="self-center w-[100px] h-[100px]" />}
                        text="I'm experienced, help me better manage AI."
                        onClick={() => handleSelection('experienced')}
                        className="bg-[linear-gradient(242deg,rgba(255,255,255,0.20)_15.39%,rgba(100,70,235,0.20)_76.4%),radial-gradient(105.5%_110.81%_at_71.89%_0%,#0A458F_0%,#603FEC_26.5%,#64BDFE_66.86%,#FFF_100%)]"
                        textColor="text-white"
                    />
                </div>
                <Button
                    variant="outline"
                    className="w-18 self-end bg-transparent text-white mt-5 hover:text-black"
                    onClick={() => handleSelection('skip')}
                >
                    Dismiss
                </Button>
            </DialogContent>
        </Dialog>
    )
}

interface SelectionCardProps {
    icon: React.ReactNode
    text: string
    onClick: () => void
    className: string
    textColor: string
}

const SelectionCard: React.FC<SelectionCardProps> = ({
    icon,
    text,
    onClick,
    className,
    textColor,
}) => (
    <button
        type="button"
        onClick={onClick}
        className={`flex flex-row gap-[30px] grow h-64 max-w-[519px] hover:opacity-95 rounded-lg cursor-pointer justify-center items-center p-10 ${className}`}
    >
        {icon}
        <p className={`text-2xl font-gilroy-bold select-none w-64 text-start ${textColor}`}>
            {text}
        </p>
    </button>
)
