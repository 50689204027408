import Azure from '@/assets/azure.svg?react'
import OpenAi from '@/assets/icons/openai.svg?react'
import Airia from '@/assets/models/Airia.svg?react'
import Anthropic from '@/assets/models/Anthropic.svg?react'
import Gemini from '@/assets/models/Gemini.svg?react'
import Mistral from '@/assets/models/Mistral.svg?react'
import Ollama from '@/assets/models/Ollama.svg?react'
import HuggingFace from '@/assets/models/huggingFace.svg?react'

const ProviderIcon: React.FC<{ provider: string; className?: string }> = ({
    provider,
    className,
}) => {
    const iconClassName = `dark:bg-white p-1 rounded-md ${className}`
    switch (provider) {
        case 'Airia':
            return <Airia className={iconClassName} />
        case 'Anthropic':
            return <Anthropic className={iconClassName} />
        case 'Azure':
            return <Azure className={iconClassName} />
        case 'Gemini':
            return <Gemini className={iconClassName} />
        case 'Hugging Face':
            return <HuggingFace className={iconClassName} />
        case 'Mistral':
            return <Mistral className={iconClassName} />
        case 'Ollama':
            return <Ollama className={iconClassName} />
        case 'OpenAI':
            return <OpenAi className={iconClassName} />
        default:
            return <Airia className={iconClassName} />
    }
}

export default ProviderIcon
