import ErrorHappened from '@/components/ErrorComponent'
import NotFoundComponent from '@/components/NotFoundComponent'
import { OnboardingSelectDialog } from '@/components/onboarding/onboarding'
import { AuthProvider, useAuth } from '@/hooks/use-auth'
import { useOnboarding } from '@/hooks/use-onboarding'
import { isApiError, isNotFound } from '@/lib/HandleErrors'
import { Outlet, createRootRoute } from '@tanstack/react-router'

export const Route = createRootRoute({
    component: Root,
    notFoundComponent: () => {
        return <NotFoundComponent />
    },
    errorComponent: ({ error }: { error: unknown }) => {
        if (isApiError(error)) {
            if (error.message == '401' || error.status == 401) {
                window.location.href = `/auth/logon?ReturnUrl=${encodeURIComponent(window.location.href)}`
                return <></>
            }
        }
        if (isNotFound(error)) {
            return <NotFoundComponent />
        }
        return <ErrorHappened />
    },
})

export function Root() {
    const { onboarding, handleOnboardingSelection } = useOnboarding()

    return (
        <AuthProvider>
            <AuthenticatedRoot />
            <OnboardingSelectDialog
                open={onboarding.selector}
                onSelectionChange={handleOnboardingSelection}
            />
        </AuthProvider>
    )
}

function AuthenticatedRoot() {
    const { isLoading } = useAuth()

    return <>{!isLoading && <Outlet />}</>
}
