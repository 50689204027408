import { Credential, deleteCredentials, getAllCredentials } from '@/api/credentials.ts'
import { getAllProjects } from '@/api/projects'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import PaginationHeader, {
    paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors.tsx'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    Link,
    Outlet,
    SearchSchemaInput,
    createFileRoute,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { Eye, MoreHorizontal, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_mainLayout/credentials')({
    component: () => {
        return <AllCredentials />
    },
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function AllCredentials() {
    const { t } = useTranslation()
    const params = Route.useParams()
    const navigate = useNavigate()
    const router = useRouter()
    const queryClient = useQueryClient()
    const deleteMutation = useMutation({
        mutationFn: deleteCredentials,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ALL_CREDENTIALS] })
            toast({
                title: t('success_title'),
                description: t('edit_credentials.delete_success_description'),
            })
        },
    })
    const queryParams = Route.useSearch()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const GetAllCredentials = useQuery({
        queryKey: [QueryKeys.ALL_CREDENTIALS, queryParams],
        queryFn: () =>
            getAllCredentials(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams }
                    : { pageSize: 25, pageNumber: 1 }
            ),
    })

    const GetAllProjects = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: getAllProjects,
    })

    const handleRowClick = (row: { original: Credential }) => {
        navigate({
            to: `/credentials/${row.original.id}`,
            params: { credentialsId: row.original.id },
            search: { ...queryParams },
        })
    }

    const handleProjectName = (projectId: string) => {
        if (GetAllProjects.data && GetAllProjects.data.length > 0) {
            const project = GetAllProjects.data.find((p) => p.id === projectId)
            if (project) {
                return project.name
            }
        }
        return 'Project'
    }

    const handleDeleteCredentials = async (apiKeyId: string) => {
        try {
            await deleteMutation.mutateAsync(apiKeyId)
            await router.invalidate()
        } catch (error) {
            HandleDeleteError(error, 'credentials')
        }
    }

    const columns: ColumnDef<Credential>[] = [
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'type',
            header: 'Type',
        },
        {
            accessorKey: 'tenantId',
            header: t('scope'),
            cell: ({ row }) => {
                const name = row.original.projectId
                    ? `Project - ${handleProjectName(row.original.projectId ?? '')}`
                    : 'Tenant'
                return <p>{name}</p>
            },
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1 ">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem>
                                <Link
                                    to="/credentials/$credentialsId"
                                    params={{
                                        credentialsId: row.original.id,
                                    }}
                                    search={{ queryParams }}
                                    className="flex gap-1"
                                >
                                    <Eye className="h-4 w-4" />
                                    {t('edit_credentials.see_credentials')}
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="flex gap-1 text-red-500"
                                onClick={() => {
                                    handleDeleteCredentials(row.original.id)
                                }}
                            >
                                <Trash className="h-4 w-4" />
                                {t('edit_credentials.delete_credentials')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                //@ts-ignore
                sortBy: columns.find((col) => col.accessorKey === field) != null ? field : 'name',
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className={'flex flex-col gap-2 pr-2 pt-6'}>
            <div className={'flex justify-between items-center'}>
                <div className="flex gap-2 items-center">
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns}
                    />
                </div>
                <Link to={'/credentials/new'}>
                    <Button>{t('new_credentials.new_credentials')}</Button>
                </Link>
            </div>
            <DefaultDataTable
                data={GetAllCredentials.data?.items ?? []}
                columns={columns}
                onRowClick={handleRowClick}
                rowsPerPage={queryParams.pageSize}
                serverPagination
                totalCount={GetAllCredentials.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Outlet />
        </div>
    )
}
