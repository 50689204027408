// de.ts

export const germanTranslation = {
    home: 'Heim',
    pipelines: 'Pipelines',
    models: 'Modelle',
    datasets: 'Datensätze',
    prompts: 'Prompts',
    api_keys: 'API-Schlüssel',
    memory: 'Speicher',
    administration: 'Verwaltung',
    language: 'Sprache',
    english: 'Englisch',
    spanish: 'Spanisch',
    italian: 'Italienisch',
    german: 'Deutsch',
    french: 'Französisch',
    portuguese: 'Portugiesisch',
    logout: 'Abmelden',
    toggle_theme: 'Thema umschalten',
    error_occurred: 'Ein Fehler ist aufgetreten',
    airia_platform: 'Airia-Plattform',
    login: 'Anmelden',
    uptime: 'Betriebszeit',
    costs_per_month: 'Kosten / Monat',
    prompts_per_month: 'Prompts / Monat',
    at: 'um',
    add_pipeline_step: 'Pipeline-Schritt hinzufügen',
    objects: 'Objekte',
    model: 'Modell',
    dataset: 'Datensatz',
    actions: 'Aktionen',
    pipeline: 'Pipeline',
    code_execution: 'Code-Ausführung',
    recall_memory: 'Speicher abrufen',
    store_memory: 'Speicher speichern',
    loading: 'Laden',
    max_number_of_results: 'Max. Anzahl der Ergebnisse',
    relevance_percentage: '% Relevanz',
    input: 'Eingabe',
    load: 'Laden',
    store: 'Speichern',
    search: 'Suchen',
    select_a_type: 'Typ auswählen',
    temperature: 'Temperatur',
    select_prompt: 'Prompt auswählen...',
    search_prompts: 'Prompts suchen...',
    no_model_found: 'Kein Modell gefunden.',
    node_info: 'Knoteninformation',
    delete: 'Löschen',
    name: 'Name',
    guid: 'GUID',
    step_guid: 'Schritt-GUID',
    api_url: 'API-URL',
    api_key: 'API-Schlüssel',
    output: 'Ausgabe',
    version: 'Version',
    error_title: 'Uh oh! Etwas ist schief gelaufen.',
    error_description: 'Beim Erstellen der Pipeline ist ein Fehler aufgetreten.',
    pipeline_name: 'Pipeline-Name',
    name_validation:
        'Der Name darf nur Buchstaben, Ziffern, Leerzeichen und die Zeichen - ~ . _ enthalten',
    other: 'Andere',
    toggle: 'Umschalten',
    playground: 'Spielplatz',
    try_out_pipeline: 'Testen Sie Ihre Pipeline',
    request: 'Anfrage',
    ask_anything: 'Frag irgendwas',
    save_as: 'Speichern als',
    save: 'Speichern',
    unique_pipeline_name: 'Der neue Pipeline-Name muss eindeutig sein.',
    save_new_pipeline: 'Neue Pipeline speichern',
    create_new_pipeline: 'Dies erstellt eine neue Pipeline mit der aktuellen Konfiguration.',
    new_pipeline_name: 'Neuer Pipeline-Name',
    python_code_execution: 'Python-Code-Ausführung',
    view_api_key: 'API-Schlüssel anzeigen',
    created_date: 'Erstellungsdatum',
    error_description_api: 'Beim Löschen des API-Schlüssels ist ein Fehler aufgetreten.',
    open_menu: 'Menü öffnen',
    see_api_key: 'API-Schlüssel anzeigen',
    delete_api_key: 'API-Schlüssel löschen',
    new_api_key: 'Neuer API-Schlüssel',
    create_new_api_key: 'Neuen API-Schlüssel erstellen',
    key: 'Schlüssel',
    create: 'Erstellen',
    data_source: 'Datenquelle',
    select_data_source: 'Datenquelle auswählen',
    azure_blob_storage: 'Azure Blob Storage',
    container_name: 'Container-Name',
    database: 'Datenbank',
    select_database: 'Datenbank auswählen',
    pinecone_api_key: 'Pinecone API-Schlüssel',
    pinecone_index_name: 'Pinecone Index-Name',
    connection_string: 'Verbindungszeichenfolge',
    embedding_model: 'Einbettungsmodell',
    select_embedding_model: 'Einbettungsmodell auswählen',
    chunking_strategy: 'Chunking-Strategie',
    select_chunking_strategy: 'Chunking-Strategie auswählen',
    airia_magic: 'Airia Magie',
    custom: 'Benutzerdefiniert',
    markdown: 'Markdown',
    recursive: 'Rekursiv',
    html: 'HTML',
    token: 'Token',
    character: 'Zeichen',
    chunk_size: 'Chunk-Größe',
    chunk_overlap: 'Chunk-Überlappung',
    separator: 'Trennzeichen',
    encoding_name: 'Codierungsname',
    reprocess: 'Neu verarbeiten',
    update: 'Aktualisieren',
    loginPage: {
        header: 'Beschleunigen Sie Ihre KI-Reise',
        enterEmail: 'Geben Sie Ihre E-Mail-Adresse ein',
        continue: 'Weiter',
        newToAiria: 'Neu bei Airia?',
        getStarted: 'Erste Schritte',
        troubleLoggingIn: 'Probleme beim Anmelden?',
    },
    error_description_model: 'Beim Aktualisieren des Modells ist ein Fehler aufgetreten.',
    columns: {
        name: 'Name',
        executionName: 'Ausführungsname',
        status: 'Status',
        lastUpdated: 'Zuletzt aktualisiert',
    },
    actions_data_source: {
        openMenu: 'Menü öffnen',
        label: 'Aktionen',
        edit: 'Datenquelle bearbeiten',
        delete: 'Datenquelle löschen',
    },
    data_source_title: 'Datenquellen',
    newDataSource: 'Neue Datenquelle',
    createDataset: {
        title: 'Neuen Datensatz erstellen',
        dataSource: 'Datenquelle',
        selectDataSource: 'Datenquelle auswählen',
        name: 'Name',
        namePatternTitle:
            'Der Name darf nur Buchstaben, Ziffern, Leerzeichen und die Zeichen - ~ . _ enthalten',
        containerName: 'Container-Name',
        database: 'Datenbank',
        selectDatabase: 'Datenbank auswählen',
        pineconeApiKey: 'Pinecone API-Schlüssel',
        pineconeIndexName: 'Pinecone Index-Name',
        connectionString: 'Verbindungszeichenfolge',
        embeddingModel: 'Einbettungsmodell',
        selectEmbeddingModel: 'Einbettungsmodell auswählen',
        chunkingStrategy: 'Chunking-Strategie',
        selectChunkingStrategy: 'Chunking-Strategie auswählen',
        airiaMagic: 'Airia Magie',
        custom: 'Benutzerdefiniert',
        markdown: 'Markdown',
        recursive: 'Rekursiv',
        html: 'HTML',
        token: 'Token',
        character: 'Zeichen',
        chunkSize: 'Chunk-Größe',
        chunkOverlap: 'Chunk-Überlappung',
        separator: 'Trennzeichen',
        encodingName: 'Codierungsname',
        create: 'Erstellen',
    },
    editMemory: {
        success_title: 'Erfolg!',
        success_description_prompt: 'Speicher erfolgreich aktualisiert.',
        error_title: 'Uh oh! Etwas ist schief gelaufen.',
        error_description: 'Beim Aktualisieren des Speichers ist ein Fehler aufgetreten.',
        duplicateNameError:
            'Eine Erinnerung mit demselben Namen existiert bereits in dieser Anwendung.',
        name: 'Name',
        type: 'Typ',
        selectType: 'Typ auswählen',
        submit: 'Senden',
        types: {
            user: 'Benutzer',
            conversation: 'Konversation',
        },
    },
    editModel: {
        successTitle: 'Erfolg!',
        successDescription: 'Modell erfolgreich aktualisiert.',
        errorTitle: 'Uh oh! Etwas ist schief gelaufen.',
        errorDescription: 'Beim Aktualisieren des Modell ist ein Fehler aufgetreten.',
        displayName: 'Anzeigename',
        provider: 'Modellanbieter',
        selectProvider: 'Modellanbieter auswählen',
        endpoint: 'Modell-Endpunkt',
        apiKey: 'API-Schlüssel',
        modelName: 'Modellname',
        modelInput: 'Modelleingabe',
        selectType: 'Eingabetyp des Modells auswählen',
        systemPrompt: 'System-Prompt',
        selectSystemPrompt: 'System-Prompt auswählen',
        searchPrompts: 'Prompts suchen...',
        noModelFound: 'Kein Modell gefunden.',
        submit: 'Senden',
        types: {
            text: 'Text',
            image: 'Bild',
        },
    },
    memoryIndex: {
        memory: 'Speicher',
        newMemory: 'Neuer Speicher',
        openMenu: 'Menü öffnen',
        actions: 'Aktionen',
        editMemory: 'Speicher bearbeiten',
        deleteMemory: 'Speicher löschen',
    },
    newMemory: {
        name: 'Name',
        type: 'Typ',
        selectType: 'Typ auswählen',
        submit: 'Senden',
        types: {
            user: 'Benutzer',
            conversation: 'Konversation',
        },
        duplicateNameError:
            'Eine Erinnerung mit demselben Namen existiert bereits in dieser Anwendung.',
        generalError: 'Beim Erstellen der Erinnerung ist ein Fehler aufgetreten.',
    },
    newModel: {
        displayName: 'Anzeigename',
        provider: 'Modellanbieter',
        selectProvider: 'Modellanbieter auswählen',
        endpoint: 'Modell-Endpunkt',
        apiKey: 'API-Schlüssel',
        modelName: 'Modellname',
        modelInput: 'Modelleingabe',
        selectType: 'Eingabetyp des Modells auswählen',
        systemPrompt: 'System-Prompt',
        selectSystemPrompt: 'System-Prompt auswählen',
        searchPrompts: 'Prompts suchen...',
        noModelFound: 'Kein Modell gefunden.',
        submit: 'Senden',
        types: {
            text: 'Text',
            image: 'Bild',
        },
    },
    columns_models: {
        name: 'Name',
        executionName: 'Ausführungsname',
        lastUpdated: 'Zuletzt aktualisiert',
    },
    actions_models: {
        openMenu: 'Menü öffnen',
        label: 'Aktionen',
        edit: 'Modell bearbeiten',
        delete: 'Modell löschen',
    },
    create_new_model: 'Neues Modell erstellen',
    text: 'Text',
    image: 'Bild',
    save_changes: 'Änderungen speichern',
    edit: 'Bearbeiten',
    success_title: 'Erfolg!',
    pipeline_updated_successfully: 'Pipeline erfolgreich aktualisiert.',
    error_description_pipeline: 'Beim Aktualisieren der Pipeline ist ein Fehler aufgetreten.',
    columns_pipelines: {
        name: 'Name',
        status: 'Status',
        version: 'Version',
        modified: 'Geändert',
        stats: 'Statistiken',
    },
    actions_pipelines: {
        label: 'Aktionen',
        edit: 'Pipeline bearbeiten',
        connection_info: 'Verbindungsinformationen',
        delete: 'Pipeline löschen',
    },
    sort_by: 'Sortieren nach',
    date_modified: 'Datum der Änderung',
    new_pipeline: 'Neue Pipeline',
    status_ready: 'Bereit',
    success_description_prompt: 'Prompt erfolgreich aktualisiert.',
    error_description_prompt: 'Beim Aktualisieren des Prompts ist ein Fehler aufgetreten.',
    select_version: 'Version auswählen',
    active: 'Aktiv',
    set_active: 'Als aktiv setzen',
    description: 'Beschreibung',
    messages: 'Nachrichten',
    add_message: 'Nachricht hinzufügen',
    new_prompt: 'Neuer Prompt',
    active_version: 'Aktive Version',
    latest_version: 'Neueste Version',
    last_modified: 'Zuletzt geändert',
    unknown_date: 'Unbekanntes Datum',
    edit_prompt: 'Prompt bearbeiten',
    delete_prompt: 'Prompt löschen',
    delete_prompt_confirmation: 'Sind Sie sicher, dass Sie diesen Prompt löschen möchten?',
    delete_prompt_error: 'Fehler beim Löschen des Prompts:',
    error_description_create_prompt: 'Fehler beim Erstellen des Prompts:',
    project: 'Anwendung',
    projects: 'Anwendungen',
    applicaton_description: 'Anwendungen erstellen und anzeigen.',
    project_create: 'Anwendung erstellen',
    project_view: 'Anwendung anzeigen',
    project_delete: 'Anwendung löschen',
    back: 'Zurück',
    groups: 'Gruppen',
    users: 'Benutzer',
    permissions: 'German',
    data_sources: 'Datenquellen',
    workbench: 'Werkbank',
    exit: 'Beenden',
    add_component: 'Komponente hinzufügen',
    type_your_message: 'Geben Sie Ihre Nachricht ein...',
    prompt_versions: 'Eingabeaufforderungsversionen',
    select_a_model: 'Wählen Sie ein Modell',
    select_a_prompt: 'Wählen Sie eine Eingabeaufforderung',
    select_a_prompt_version: 'Wählen Sie eine Eingabeaufforderungsversion',
    remove_chat_component: 'Chat-Komponente entfernen',
    clear_files: 'Dateien löschen',
    upload_files: 'Dateien hochladen',
    send_message: 'Nachricht senden',
    page_size: 'Seitenanzahl',
    workspaces: {
        actions: {
            open_menu: 'Menü öffnen',
            access_control: 'Zugriffskontrolle',
        },
        workspace: 'Arbeitsbereich',
        workspaces: 'Arbeitsbereiche',
        workspaces_description: 'Arbeitsbereiche erstellen und anzeigen.',
        workspace_create: 'Arbeitsbereich erstellen',
        access_control: 'Zugriffskontrolle',
        workspace_create_success: 'Arbeitsbereich erfolgreich erstellt.',
        select_workspace: 'Wählen Sie Ihren Arbeitsbereich',
        switching_workspace_header: 'Fast geschafft!',
        switching_workspace_message: 'Wechsel des Arbeitsbereichs, bitte warten.',
        workspace_switched_header: 'Fertig!',
        workspace_switched_message: 'Bereit, den Arbeitsbereich zu betreten.',
        access_new_workspace: 'Arbeitsbereich betreten',
        error_description: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut.',
    },
} as const
