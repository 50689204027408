import { runPipeline } from './pipelineExecution'

export const sendHelpCenterChatMessage = async (
    message: string,
    pipeline: string,
    apiKey: string
) => {
    if (pipeline.trim() == '') return
    return await runPipeline(
        {
            userInput: message,
            debug: false,
        },
        pipeline,
        apiKey
    )
}
