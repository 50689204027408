import SelectOption from '@/components/settings/SelectOption.tsx'
import React, { useEffect } from 'react'
import { Separator } from '@/components/ui/separator.tsx'
import { Button } from '@/components/ui/button.tsx'
import { downloadOperationsCsv } from '@/api/aiOperationsExecution.ts'
import { downloadCsv } from '@/lib/utils.ts'
import { startOfMonth, endOfMonth, subMonths, subDays, startOfDay } from 'date-fns'
import { DatePicker } from '@/components/ui/date-picker.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { CaretDownIcon } from '@radix-ui/react-icons'

interface ExportFormProps {
    currentWindow?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
}

const ExportForm: React.FC<ExportFormProps> = ({ currentWindow }) => {
    const [scope, setScope] = React.useState<'current' | 'all' | 'custom'>('current')
    const [start, setStart] = React.useState<Date | undefined>(undefined)
    const [end, setEnd] = React.useState<Date | undefined>(undefined)
    const [rangeOption, setRangeOption] = React.useState<
        'thisMonth' | 'lastMonth' | 'last90Days' | 'custom'
    >('thisMonth')

    useEffect(() => {
        if (scope === 'all') {
            setStart(undefined)
            setEnd(undefined)
        } else if (scope === 'current') {
            if (currentWindow === 'thisMonth') {
                const now = new Date()
                setStart(startOfMonth(now))
                setEnd(endOfMonth(now))
            } else if (currentWindow === 'lastMonth') {
                const lastMonth = subMonths(new Date(), 1)
                setStart(startOfMonth(lastMonth))
                setEnd(endOfMonth(lastMonth))
            } else if (currentWindow === 'last90Days') {
                const now = new Date()
                const ninetyDaysAgo = subDays(now, 90)
                setStart(startOfDay(ninetyDaysAgo))
                setEnd(startOfDay(now))
            } else if (typeof currentWindow === 'number') {
                setStart(new Date(currentWindow))
                setEnd(undefined)
            } else {
                setStart(undefined)
                setEnd(undefined)
            }
        }
    }, [scope, currentWindow])

    useEffect(() => {
        if (rangeOption === 'thisMonth') {
            setStart(startOfMonth(Date.now()))
            setEnd(undefined)
        } else if (rangeOption === 'lastMonth') {
            setStart(startOfMonth(subMonths(Date.now(), 1)))
            setEnd(endOfMonth(subMonths(Date.now(), 1)))
        } else if (rangeOption === 'last90Days') {
            setStart(subDays(Date.now(), 90))
        }
    }, [rangeOption])

    const onExportClick = () => {
        downloadOperationsCsv(start?.getTime(), end?.getTime()).then((data) => {
            downloadCsv(data, `operations-${Date.now()}.csv`)
        })
    }

    const getDropdownTriggerLabel = () => {
        switch (rangeOption) {
            case 'thisMonth':
                return 'Current Month'
            case 'lastMonth':
                return 'Previous Month'
            case 'last90Days':
                return 'Past 90 Days'
            case 'custom':
                return 'Custom Date Range'
            default:
                return 'Select a range'
        }
    }

    return (
        <div className={'flex flex-col gap-2'}>
            <div className={'flex flex-col gap-2 p-4'}>
                <span>Export Scope</span>
                <div className={'flex gap-4'}>
                    <SelectOption
                        checked={scope === 'current'}
                        value={'current'}
                        label={'Current Page'}
                        onChange={(value) => setScope(value as 'current')}
                    />
                    <SelectOption
                        checked={scope === 'all'}
                        value={'all'}
                        label={'All Transactions'}
                        onChange={(value) => setScope(value as 'all')}
                    />
                    <SelectOption
                        checked={scope === 'custom'}
                        value={'custom'}
                        label={'Custom Date Range'}
                        onChange={(value) => setScope(value as 'custom')}
                    />
                </div>
            </div>
            {scope === 'custom' && (
                <div className={'flex flex-col gap-2 p-4'}>
                    <span className={'text-xs'}>
                        Export will include <b>all transactions</b> within the following date range:
                    </span>
                    <span>Range</span>
                    <div className={'flex gap-4'}>
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <Button variant={'outline'}>
                                    {getDropdownTriggerLabel()} <CaretDownIcon />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem onClick={() => setRangeOption('thisMonth')}>
                                    Current Month
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('lastMonth')}>
                                    Previous Month
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('last90Days')}>
                                    Past 90 Days
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => setRangeOption('custom')}>
                                    Custom Date Range
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                        {rangeOption === 'custom' && (
                            <DatePicker placeholder={'Select start date'} onDateSelect={setStart} />
                        )}{' '}
                        {rangeOption === 'custom' && (
                            <DatePicker placeholder={'Select end date'} onDateSelect={setEnd} />
                        )}
                    </div>
                </div>
            )}
            <Separator />
            <div className={'flex justify-end gap-6'}>
                <Button onClick={() => onExportClick()}>Export</Button>
            </div>
        </div>
    )
}

export default ExportForm
