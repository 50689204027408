import React from 'react'
import Dropbox from '@/assets/connectors/dropbox_symbol.svg?react'
import GoogleDrive from '@/assets/connectors/googledrive.png'
import ICloud from '@/assets/connectors/icloud_logo.svg?react'
import OneDrive from '@/assets/connectors/onedrive_logo.svg?react'
import Salesforce from '@/assets/connectors/salesforce_logo.svg?react'
import Slack from '@/assets/connectors/slack.svg?react'
import SharePoint from '@/assets/connectors/sharepoint_logo.svg?react'

const ConnectorsComingSoon: React.FC = () => {
    const className = 'h-10 w-10'
    const upcomingConnectors = [
        <Salesforce key={'salesforce'} className={className} />,
        <Dropbox key={'dropbox'} className={className} />,
        <OneDrive key={'onedrive'} className={className} />,
        <Slack key={'slack'} className={className} />,
        <SharePoint key={'sharepoint'} className={className} />,
        <ICloud key={'icloud'} className={className} />,
        <img key={'google-drive'} src={GoogleDrive} alt={'google-drive'} className={className} />,
    ]
    return (
        <div className="flex flex-col items-center justify-center p-10">
            <h1 className="text-xl font-gilroy-medium mb-4">More options coming soon!</h1>
            <div className={'flex gap-4 items-center'}>{upcomingConnectors.map((c) => c)}</div>
        </div>
    )
}

export default ConnectorsComingSoon
