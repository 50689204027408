// it.ts

export const italianTranslation = {
    home: 'Casa',
    pipelines: 'Pipeline',
    models: 'Modelli',
    datasets: 'Dataset',
    prompts: 'Prompt',
    api_keys: 'Chiavi API',
    memory: 'Memoria',
    administration: 'Amministrazione',
    language: 'Lingua',
    english: 'Inglese',
    spanish: 'Spagnolo',
    italian: 'Italiano',
    german: 'Tedesco',
    french: 'Francese',
    portuguese: 'Portoghese',
    logout: 'Logout',
    toggle_theme: 'Cambia tema',
    error_occurred: 'Si è verificato un errore',
    airia_platform: 'Piattaforma Airia',
    login: 'Accedi',
    uptime: 'Tempo di attività',
    costs_per_month: 'Costi / mese',
    prompts_per_month: 'Prompt / mese',
    at: 'alle',
    add_pipeline_step: 'Aggiungi Passo Pipeline',
    objects: 'Oggetti',
    model: 'Modello',
    dataset: 'Dataset',
    actions: 'Azioni',
    pipeline: 'Pipeline',
    code_execution: 'Esecuzione Codice',
    recall_memory: 'Richiama Memoria',
    store_memory: 'Memorizza Memoria',
    loading: 'Caricamento',
    max_number_of_results: 'Numero massimo di risultati',
    relevance_percentage: '% Rilevanza',
    input: 'Ingresso',
    load: 'Carica',
    store: 'Memorizza',
    search: 'Cerca',
    select_a_type: 'Seleziona un tipo',
    temperature: 'Temperatura',
    select_prompt: 'Seleziona prompt...',
    search_prompts: 'Cerca Prompt...',
    no_model_found: 'Nessun modello trovato.',
    node_info: 'Informazioni Nodo',
    delete: 'Elimina',
    name: 'Nome',
    guid: 'GUID',
    step_guid: 'GUID del Passo',
    api_url: 'URL API',
    api_key: 'Chiave API',
    output: 'Output',
    version: 'Versione',
    error_title: 'Uh oh! Qualcosa è andato storto.',
    error_description: 'Si è verificato un problema durante la creazione della pipeline.',
    pipeline_name: 'Nome Pipeline',
    name_validation: 'Il nome può contenere solo lettere, cifre, spazi e i caratteri - ~ . _',
    other: 'Altro',
    toggle: 'Alterna',
    playground: 'Playground',
    try_out_pipeline: 'Prova la tua pipeline',
    request: 'Richiesta',
    ask_anything: 'Chiedi qualsiasi cosa',
    save_as: 'Salva come',
    save: 'Salva',
    unique_pipeline_name: 'Il nuovo nome della pipeline deve essere univoco.',
    save_new_pipeline: 'Salva nuova pipeline',
    create_new_pipeline: 'Questo creerà una nuova pipeline con la configurazione attuale.',
    new_pipeline_name: 'Nuovo Nome Pipeline',
    python_code_execution: 'Esecuzione Codice Python',
    view_api_key: 'Visualizza Chiave API',
    created_date: 'Data di Creazione',
    error_description_api: "Si è verificato un problema durante l'eliminazione della chiave API.",
    open_menu: 'Apri menu',
    see_api_key: 'Visualizza Chiave API',
    delete_api_key: 'Elimina Chiave API',
    new_api_key: 'Nuova Chiave API',
    create_new_api_key: 'Crea Nuova Chiave API',
    key: 'Chiave',
    create: 'Crea',
    data_source: 'Fonte di Dati',
    select_data_source: 'Seleziona una fonte di dati',
    azure_blob_storage: 'Azure Blob Storage',
    container_name: 'Nome del Contenitore',
    database: 'Database',
    select_database: 'Seleziona un Database',
    pinecone_api_key: 'Chiave API Pinecone',
    pinecone_index_name: 'Nome Indice Pinecone',
    connection_string: 'Stringa di Connessione',
    embedding_model: 'Modello di Embedding',
    select_embedding_model: 'Seleziona un Modello di Embedding',
    chunking_strategy: 'Strategia di Suddivisione',
    select_chunking_strategy: 'Seleziona una Strategia di Suddivisione',
    airia_magic: 'Magia di Airia',
    custom: 'Personalizzato',
    markdown: 'Markdown',
    recursive: 'Ricorsivo',
    html: 'HTML',
    token: 'Token',
    character: 'Carattere',
    chunk_size: 'Dimensione del Pezzo',
    chunk_overlap: 'Sovrapposizione del Pezzo',
    separator: 'Separatore',
    encoding_name: 'Nome Codifica',
    reprocess: 'Riprocessa',
    update: 'Aggiorna',
    loginPage: {
        header: "Accelera il tuo viaggio nell'intelligenza artificiale",
        enterEmail: 'Inserisci la tua email',
        continue: 'Continua',
        newToAiria: 'Nuovo ad Airia?',
        getStarted: 'Inizia',
        problemLoggingIn: 'Problemi di accesso?',
    },
    error_description_model: "Si è verificato un problema durante l'aggiornamento del modello.",
    columns: {
        name: 'Nome',
        executionName: 'Nome di Esecuzione',
        status: 'Stato',
        lastUpdated: 'Ultimo Aggiornamento',
    },
    actions_data_source: {
        openMenu: 'Apri menu',
        label: 'Azioni',
        edit: 'Modifica Fonte di Dati',
        delete: 'Elimina Fonte di Dati',
    },
    data_source_title: 'Fonti di Dati',
    newDataSource: 'Nuova Fonte di Dati',
    createDataset: {
        title: 'Crea Nuovo Dataset',
        dataSource: 'Fonte di Dati',
        selectDataSource: 'Seleziona una fonte di dati',
        name: 'Nome',
        namePatternTitle: 'Il nome può contenere solo lettere, cifre, spazi e i caratteri - ~ . _',
        containerName: 'Nome del Contenitore',
        database: 'Database',
        selectDatabase: 'Seleziona un Database',
        pineconeApiKey: 'Chiave API Pinecone',
        pineconeIndexName: 'Nome Indice Pinecone',
        connectionString: 'Stringa di Connessione',
        embeddingModel: 'Modello di Embedding',
        selectEmbeddingModel: 'Seleziona un Modello di Embedding',
        chunkingStrategy: 'Strategia di Suddivisione',
        selectChunkingStrategy: 'Seleziona una Strategia di Suddivisione',
        airiaMagic: 'Magia di Airia',
        custom: 'Personalizzato',
        markdown: 'Markdown',
        recursive: 'Ricorsivo',
        html: 'HTML',
        token: 'Token',
        character: 'Carattere',
        chunkSize: 'Dimensione del Pezzo',
        chunkOverlap: 'Sovrapposizione del Pezzo',
        separator: 'Separatore',
        encodingName: 'Nome Codifica',
        create: 'Crea',
    },
    editMemory: {
        success_title: 'Successo!',
        success_description_prompt: 'Memoria aggiornata con successo.',
        error_title: 'Uh oh! Qualcosa è andato storto.',
        error_description: "Si è verificato un problema durante l'aggiornamento della memoria.",
        duplicateNameError: 'Esiste già una memoria con lo stesso nome in questa applicazione.',
        name: 'Nome',
        type: 'Tipo',
        selectType: 'Seleziona un tipo',
        submit: 'Invia',
        types: {
            user: 'Utente',
            conversation: 'Conversazione',
        },
    },
    editModel: {
        success_title: 'Successo!',
        success_description_prompt: 'Modello aggiornata con successo.',
        error_title: 'Uh oh! Qualcosa è andato storto.',
        error_description: "Si è verificato un problema durante l'aggiornamento del modello.",
        displayName: 'Nome Visualizzato',
        provider: 'Fornitore Modello',
        selectProvider: 'Seleziona un tipo di input modello',
        endpoint: 'Endpoint Modello',
        apiKey: 'Chiave API',
        modelName: 'Nome Modello',
        modelInput: 'Input Modello',
        selectType: 'Seleziona un tipo di input modello',
        systemPrompt: 'Prompt di Sistema',
        selectSystemPrompt: 'Seleziona un prompt di sistema',
        searchPrompts: 'Cerca Prompt...',
        noModelFound: 'Nessun modello trovato.',
        submit: 'Invia',
        types: {
            text: 'Testo',
            image: 'Immagine',
        },
    },
    memoryIndex: {
        memory: 'Memoria',
        newMemory: 'Nuova Memoria',
        openMenu: 'Apri menu',
        actions: 'Azioni',
        editMemory: 'Modifica Memoria',
        deleteMemory: 'Elimina Memoria',
    },
    newMemory: {
        name: 'Nome',
        type: 'Tipo',
        selectType: 'Seleziona un tipo',
        submit: 'Invia',
        types: {
            user: 'Utente',
            conversation: 'Conversazione',
        },
        duplicateNameError: 'Esiste già una memoria con lo stesso nome in questa applicazione.',
        generalError: 'Si è verificato un errore durante la creazione della memoria.',
    },
    newModel: {
        displayName: 'Nome Visualizzato',
        provider: 'Fornitore Modello',
        selectProvider: 'Seleziona un tipo di input modello',
        endpoint: 'Endpoint Modello',
        apiKey: 'Chiave API',
        modelName: 'Nome Modello',
        modelInput: 'Input Modello',
        selectType: 'Seleziona un tipo di input modello',
        systemPrompt: 'Prompt di Sistema',
        selectSystemPrompt: 'Seleziona un prompt di sistema',
        searchPrompts: 'Cerca Prompt...',
        noModelFound: 'Nessun modello trovato.',
        submit: 'Invia',
        types: {
            text: 'Testo',
            image: 'Immagine',
        },
    },
    columns_models: {
        name: 'Nome',
        executionName: 'Nome di Esecuzione',
        lastUpdated: 'Ultimo Aggiornamento',
    },
    actions_models: {
        openMenu: 'Apri menu',
        label: 'Azioni',
        edit: 'Modifica Modello',
        delete: 'Elimina Modello',
    },
    create_new_model: 'Crea Nuovo Modello',
    text: 'Testo',
    image: 'Immagine',
    save_changes: 'Salva modifiche',
    edit: 'Modifica',
    success_title: 'Successo!',
    pipeline_updated_successfully: 'Pipeline aggiornata con successo.',
    error_description_pipeline:
        "Si è verificato un problema durante l'aggiornamento della pipeline.",
    columns_pipelines: {
        name: 'Nome',
        status: 'Stato',
        version: 'Versione',
        modified: 'Modificato',
        stats: 'Statistiche',
    },
    actions_pipelines: {
        label: 'Azioni',
        edit: 'Modifica Pipeline',
        connection_info: 'Informazioni di Connessione',
        delete: 'Elimina Pipeline',
    },
    sort_by: 'Ordina per',
    date_modified: 'Data di modifica',
    new_pipeline: 'Nuova Pipeline',
    status_ready: 'Pronto',
    success_description_prompt: 'Prompt aggiornato con successo.',
    error_description_prompt: "Si è verificato un problema durante l'aggiornamento del prompt.",
    select_version: 'Seleziona Versione',
    active: 'Attivo',
    set_active: 'Imposta come Attivo',
    description: 'Descrizione',
    messages: 'Messaggi',
    add_message: 'Aggiungi Messaggio',
    new_prompt: 'Nuovo Prompt',
    active_version: 'Versione Attiva',
    latest_version: 'Ultima Versione',
    last_modified: 'Ultima Modifica',
    unknown_date: 'Data Sconosciuta',
    edit_prompt: 'Modifica Prompt',
    delete_prompt: 'Elimina Prompt',
    delete_prompt_confirmation: 'Sei sicuro di voler eliminare questo prompt?',
    delete_prompt_error: "Errore durante l'eliminazione del prompt:",
    error_description_create_prompt: 'Errore durante la creazione del prompt:',
    project: 'Applicazione',
    projects: 'Applicazioni',
    applicaton_description: 'Creare e visualizzare applicazioni.',
    project_create: 'Crea applicazione',
    project_view: "Visualizza l'applicazione",
    project_delete: 'Elimina applicazione',
    back: 'Indietro',
    groups: 'Gruppi',
    users: 'Utenti',
    permissions: 'Autorizzazioni',
    data_sources: 'Origine dei dati',
    workbench: 'Banco di lavoro',
    exit: 'Esci',
    add_component: 'Aggiungi Componente',
    type_your_message: 'Scrivi il tuo messaggio...',
    prompt_versions: 'Versioni di Prompt',
    select_a_model: 'Seleziona un Modello',
    select_a_prompt: 'Seleziona un Prompt',
    select_a_prompt_version: 'Seleziona una Versione di Prompt',
    remove_chat_component: 'Rimuovi Componente di Chat',
    clear_files: 'Cancella File',
    upload_files: 'Carica File',
    send_message: 'Invia Messaggio',
    page_size: 'Dimensione della Pagina',
    workspaces: {
        actions: {
            open_menu: 'Apri menu',
            access_control: 'Controllo di accesso',
        },
        workspace: 'Spazio di lavoro',
        workspaces: 'Spazi di lavoro',
        workspaces_description: 'Creare e visualizzare spazi di lavoro.',
        workspace_create: 'Crea spazio di lavoro',
        access_control: 'Controllo di accesso',
        workspace_create_success: 'Spazio di lavoro creato con successo.',
        select_workspace: "Seleziona l'area di lavoro",
        switching_workspace_header: 'Quasi fatto!',
        switching_workspace_message: 'Cambio di spazio di lavoro, per favore aspetta.',
        workspace_switched_header: 'Fatto!',
        workspace_switched_message: 'Pronto per accedere allo spazio di lavoro.',
        access_new_workspace: 'Accedi allo spazio di lavoro',
        error_description: 'Qualcosa è andato storto durante il cambio di spazio di lavoro.',
    },
} as const
