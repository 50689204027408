import type React from 'react'
import type { PropsWithChildren } from 'react'
import Activity from '@/assets/icons/activity.svg?react'
import BarChartCircle from '@/assets/icons/bar-chart-circle.svg?react'
import BankNote from '@/assets/icons/bank-note.svg?react'
import Overview from '@/components/home/overview/Overview'
import Consumption from '@/components/home/consumption/Consumption.tsx'
import * as Tabs from '@radix-ui/react-tabs'
import { type SearchSchemaInput, createFileRoute } from '@tanstack/react-router'
import type { paginationAndSortingParams } from '@/components/pagination/paginationHeader'
import { FeedTable } from '@/components/home/feed/FeedTable'

export const Route = createFileRoute('/_mainLayout/')({
    component: Dashboard,
    //@ts-ignore
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

const DashboardTabTrigger: React.FC<PropsWithChildren & { value: string }> = ({
    children,
    value,
}) => {
    return (
        <Tabs.Trigger
            className="bg-background px-1 h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary"
            value={value}
        >
            {children}
        </Tabs.Trigger>
    )
}

function Dashboard() {
    const queryParams = Route.useSearch()
    return (
        <div className="flex flex-col gap-4 px-10">
            <Tabs.Root className="flex flex-col" defaultValue="overview">
                <Tabs.List className="shrink-0 flex border-b border-mauve6 w-full gap-10">
                    <DashboardTabTrigger value={'overview'}>
                        <BarChartCircle className="w-6 h-6 mr-2 -ml-1" />
                        Overview
                    </DashboardTabTrigger>
                    <DashboardTabTrigger value="feed">
                        <Activity className="w-6 h-6 mr-2 -ml-1" />
                        Feed
                    </DashboardTabTrigger>
                    <DashboardTabTrigger value={'consumption'}>
                        <BankNote className="w-6 h-6 mr-2 -ml-1" />
                        Consumption
                    </DashboardTabTrigger>
                </Tabs.List>
                <Tabs.Content value="overview">
                    <Overview />
                </Tabs.Content>
                <Tabs.Content value="feed">
                    <FeedTable queryParams={queryParams} params={Route.useParams()} />
                </Tabs.Content>
                <Tabs.Content value="consumption">
                    <Consumption />
                </Tabs.Content>
            </Tabs.Root>
        </div>
    )
}

export default Dashboard
