import { getHelpCenterConfiguration } from '@/api/configuration'
import { sendHelpCenterChatMessage } from '@/api/helpCenterChat'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useQuery } from '@tanstack/react-query'
import { ArrowUp, LoaderCircle } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HelpCenterChatMessageProps } from '../types'
import { HelpCenterChatMessage } from './helpCenterChatMessage'

export const HelpCenterChat = () => {
    const { t } = useTranslation()
    const { toast } = useToast()

    const [messages, setMessages] = useState<HelpCenterChatMessageProps[]>([])
    const [tempMessage, setTempMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [pipeline, setPipeline] = useState('')
    const [apiKey, setApiKey] = useState('')

    const HelpCenterConfigurationQuery = useQuery({
        queryKey: [QueryKeys.HELP_CENTER_CONFIG],
        queryFn: () => getHelpCenterConfiguration(),
    })

    const endOfMessageRef = useRef<null | HTMLDivElement>(null)

    const goToBottom = () => {
        endOfMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const sendMessage = async (message: string) => {
        if (loading || message.trim() == '') return
        setLoading(true)
        try {
            setMessages((prev) => {
                return [
                    ...prev,
                    {
                        type: 'user',
                        message: message,
                        createdAt: new Date(),
                    },
                ]
            })
            setTempMessage('')
            const response = await sendHelpCenterChatMessage(message, pipeline, apiKey)
            setMessages((prev) => {
                return [
                    ...prev,
                    {
                        type: 'system',
                        message: response?.result as string,
                        createdAt: new Date(),
                    },
                ]
            })
        } catch (e) {
            console.log(e)
            toast({
                title: t('help_center.error'),
                description: t('help_center.error_description'),
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        goToBottom()
    }, [messages])

    useEffect(() => {
        setPipeline(HelpCenterConfigurationQuery.data?.pipeline ?? '')
        setApiKey(HelpCenterConfigurationQuery.data?.apiKey ?? '')
    }, [HelpCenterConfigurationQuery.data])

    return (
        <div className="flex flex-col w-full p-0 bg-background">
            <div className="w-full h-[500px] overflow-scroll">
                {messages.map((message, index) => (
                    <HelpCenterChatMessage {...message} key={index} />
                ))}
                {loading && (
                    <div className="w-full justify-center flex flex-col">
                        <LoaderCircle className="self-center w-12 h-12 animate-spin" />
                    </div>
                )}
                <div ref={endOfMessageRef} />
            </div>
            <div className="flex flex-col w-full p-2 gap-2">
                <div className="flex flex-row w-full p-2 gap-2">
                    <Input
                        value={tempMessage}
                        placeholder={t('help_center.how_to_help')}
                        onChange={(e) => setTempMessage(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey && !loading) {
                                e.preventDefault()
                                sendMessage(tempMessage)
                            }
                        }}
                    />
                    <Button className="rounded-full" onClick={() => sendMessage(tempMessage)}>
                        <ArrowUp className="w-4"></ArrowUp>
                    </Button>
                </div>
            </div>
        </div>
    )
}
