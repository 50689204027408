import { Group, getGroupById, updateGroup } from '@/api/groups.ts'
import { User, getAllInternalUsers } from '@/api/users.ts'
import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog.tsx'
import { Label } from '@/components/ui/label.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { useDebounce } from '@uidotdev/usehooks'
import { cx } from 'class-variance-authority'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_mainLayout/groups/$id/edit')({
    loader: ({ params }) => getGroupById(params.id),
    component: () => {
        const params = Route.useParams()
        const router = useRouter()
        return (
            <Dialog modal open onOpenChange={() => router.history.back()}>
                <DialogContent className={'max-w-modal-lg max-h-modal px-0'}>
                    <EditGroup key={`edit-group-${params.id}`} />
                </DialogContent>
            </Dialog>
        )
    },
})

const getRandomColor = () => {
    return [
        'bg-green-500',
        'bg-amber-500',
        'bg-red-300',
        'bg-purple-300',
        'bg-blue-300',
        'bg-orange-300',
        'bg-yellow-500',
        'bg-cyan-300',
        'bg-pink-300',
        'bg-teal-300',
        'bg-lime-500',
        'bg-indigo-300',
        'bg-violet-300',
        'bg-fuchsia-300',
        'bg-rose-300',
    ][Math.floor(Math.random() * 15)]
}
function EditGroup() {
    const group = Route.useLoaderData()
    const [selectedUsers, setSelectedUsers] = useState([] as User[])
    /* const router = useRouter() */
    const { t } = useTranslation()

    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const queryClient = useQueryClient()
    const options = {
        page: 1,
        pageSize: 10,
        filter: '',
    }
    const [userQuery, setUserQuery] = useState(options)
    const GetAllUsersQuery = useQuery({
        queryKey: [QueryKeys.ALL_USERS, userQuery],
        queryFn: () => getAllInternalUsers(userQuery ?? { page: 1, pageSize: 10 }),
    })
    const [suggestedUsers, setSuggestedUsers] = useState([] as User[])

    const updateGroupMutation = useMutation({
        mutationFn: (data: Group) => updateGroup(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.GROUPS] })
            toast({
                title: t('success_title'),
                description: t('success_description_prompt'),
                variant: 'default',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })
    const appForm = useForm<Group>({
        defaultValues: {
            ...group,
            users: selectedUsers,
        },
    })

    const limit = 12
    const displayedUsers = selectedUsers.slice(0, limit)

    const handleSearch = (searchText: string) => {
        setSearchTerm(searchText)
    }

    const onSubmit = async (data: Group) => {
        try {
            await updateGroupMutation.mutateAsync({
                ...data,
                users: selectedUsers,
            })
        } catch (error) {
            console.error(error)
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        }
    }

    const handleAddUser = (user: User) => {
        setSelectedUsers([...selectedUsers, user])
        user.isSelected = true
    }

    const handleRemoveUser = (user: User) => {
        setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
        user.isSelected = false
        setSuggestedUsers([...suggestedUsers, user])
    }

    const isUserSelected = (user: User) => {
        return selectedUsers.some((selectedUser) => selectedUser.id === user.id)
    }

    useEffect(() => {
        setSelectedUsers(group.users || [])
    }, [group.users])

    useEffect(() => {
        const query = { ...userQuery, filter: debouncedSearchTerm }
        setUserQuery(query)
        GetAllUsersQuery.refetch()
    }, [debouncedSearchTerm])

    return (
        <form
            onSubmit={appForm.handleSubmit(onSubmit)}
            className="max-h-modal flex flex-col justify-between"
        >
            <DialogHeader className="-mt-3 mb-2 px-5">{group.name}</DialogHeader>
            <div className={'overflow-auto max-h-[625px] px-5'}>
                <div className={'grid grid-cols-4'}>
                    <div></div>
                    <div className={'col-span-4'}>
                        <div className={'flex flex-col gap-4'}>
                            <div>
                                <Label htmlFor="addUser" className="text-left">
                                    {t('addUsers')}{' '}
                                </Label>
                                <div className="flex gap-4">
                                    <input
                                        type="text"
                                        placeholder={t('search')}
                                        className="w-full border border-gray-300 rounded-md p-2"
                                        value={searchTerm}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label htmlFor="suggestedUsers" className="text-left">
                                    {t('suggestedUsers')}
                                </Label>
                                <ul className="overflow-auto h-60 w-96 border border-b-0 border-gray-200">
                                    {GetAllUsersQuery.data?.items.map((user) => (
                                        <li
                                            key={user.id}
                                            className="flex h-10 items-center justify-center gap-1 border-b border-gray-200"
                                        >
                                            <div className="ml-2">
                                                <span
                                                    className={cx(
                                                        getRandomColor(),
                                                        'text-white text-md px-2 py-1 rounded-full justify-start'
                                                    )}
                                                >
                                                    {user.firstName[0]}
                                                    {user.lastName[0]}
                                                </span>
                                            </div>
                                            <h6 className="flex-1 text-sm text-slate-600">
                                                {user.firstName} {user.lastName}
                                            </h6>

                                            <button
                                                type="button"
                                                className="group block h-full w-16 hover:bg-slate-400"
                                                onClick={() =>
                                                    isUserSelected(user)
                                                        ? handleRemoveUser(user)
                                                        : handleAddUser(user)
                                                }
                                            >
                                                <span className="text-lg text-slate-400 group-hover:text-slate-200">
                                                    {isUserSelected(user) ? '✔' : '+'}
                                                </span>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <Label htmlFor="selectedUsers" className="text-left">
                                    {t('selectedUsers')}
                                </Label>
                                <ul className="overflow-auto h-20 flex flex-wrap">
                                    {displayedUsers.map((user) => (
                                        <li key={user.id} className="flex items-center">
                                            <div className="p-2">
                                                <Button
                                                    className={cx(
                                                        getRandomColor(),
                                                        'group inline-block rounded-full p-4 hover:bg-slate-200 hover:p-2 h-12 w-12 text-xs'
                                                    )}
                                                    onClick={() => handleRemoveUser(user)}
                                                    title="{user.firstName}{' '}{user.lastName}"
                                                >
                                                    <span
                                                        className={cx(
                                                            'group-hover:hidden text-gray-200'
                                                        )}
                                                    >
                                                        {user.firstName[0]}
                                                        {user.lastName[0]}
                                                    </span>
                                                    <span className="hidden group-hover:inline text-gray-400 text-xl">
                                                        X
                                                    </span>
                                                </Button>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="flex items-center">
                                        {selectedUsers.length > 0 && `+${selectedUsers.length}`}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="px-5 mt-2">
                <Button className="w-16" onClick={appForm.handleSubmit(onSubmit)} type="submit">
                    {t('save')}
                </Button>
            </div>
        </form>
    )
}
