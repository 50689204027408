import { DataSourceGroup, DeleteDataSourceGroup } from '@/api/dataSourceGroup'
import { DeleteDataset } from '@/api/datasets'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useToast } from '@/components/ui/use-toast'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { ChevronDown, FolderPlus, MoreHorizontal, Trash } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentItem } from './DocumentItem'
import { NewFolder } from './NewFolder'

interface DataSourceGroupItemProps {
    group: DataSourceGroup
    isExpanded: boolean
    onToggleExpand: () => void
    selectedDatasets: Set<string>
    onToggleDataset: (id: string) => void
    onToggleAllInGroup: (groupId: string, isSelected: boolean) => void
    projectId: string
}

export const DataSourceGroupItem: React.FC<DataSourceGroupItemProps> = ({
    group,
    isExpanded,
    onToggleExpand,
    selectedDatasets,
    onToggleDataset,
    onToggleAllInGroup,
    projectId,
}) => {
    const navigate = useNavigate()
    const [openNewFolder, setOpenNewFolder] = useState(false)
    const [isGroupSelected, setIsGroupSelected] = useState(false)
    const { toast } = useToast()
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    useEffect(() => {
        const allDataSourcesInGroup = group.dataSources?.map((ds) => ds.id) || []
        const isAllSelected =
            allDataSourcesInGroup.length > 0 &&
            allDataSourcesInGroup.every((id) => selectedDatasets.has(id))
        setIsGroupSelected(isAllSelected)
    }, [group.dataSources, selectedDatasets])

    const handleGroupSelection = () => {
        const newSelectionState = !isGroupSelected
        setIsGroupSelected(newSelectionState)
        onToggleAllInGroup(group.id, newSelectionState)
    }

    const deleteDataSourceGroupMutation = useMutation({
        mutationFn: DeleteDataSourceGroup,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASOURCE_GROUPS],
            })
            toast({
                title: t('success_title'),
                description: 'Data Source Group successfully deleted.',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const deleteDatasetMutation = useMutation({
        mutationFn: DeleteDataset,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_DATASETS],
            })
            toast({
                title: t('success_title'),
                description: 'Dataset successfully deleted.',
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleDeleteDataset = async (datasetId: string) => {
        try {
            await deleteDatasetMutation.mutateAsync(datasetId)
        } catch (error) {
            console.error('error deleting dataset', error)
        }
    }

    const handleDeleteDataSourceGroup = async () => {
        try {
            await deleteDataSourceGroupMutation.mutateAsync(group.id)
        } catch (error) {
            console.error('error deleting data source group', error)
        }
    }

    return (
        <div className="mb-2">
            <div className="flex items-center justify-between py-2">
                <div className="flex items-center cursor-pointer gap-2" onClick={onToggleExpand}>
                    <ChevronDown
                        className={`w-4 h-4 transition-transform cursor-pointer ${
                            isExpanded ? '' : 'transform -rotate-180'
                        }`}
                    />
                    <p className="text-sm font-medium">{group.name}</p>
                </div>
                <div className="flex items-center gap-2">
                    <Button
                        size="sm"
                        variant="ghost"
                        onClick={() => setOpenNewFolder(true)}
                        className=""
                    >
                        <FolderPlus className="w-4 h-4" />
                    </Button>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0 hover:text-black">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel className="flex gap-1">
                                {t('actions')}
                            </DropdownMenuLabel>
                            <DropdownMenuItem
                                onClick={handleDeleteDataSourceGroup}
                                className="flex gap-1 text-red-500"
                            >
                                <Trash className="h-4 w-4" />
                                Delete Client
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <button
                        className={`w-5 h-5 rounded-full ${
                            isGroupSelected ? 'bg-green-500' : 'bg-gray-200'
                        } flex items-center justify-center transition-colors duration-200`}
                        onClick={handleGroupSelection}
                    >
                        {isGroupSelected && (
                            <svg
                                className="w-3 h-3 text-white"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20 6L9 17L4 12"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                    </button>
                </div>
            </div>
            <NewFolder
                open={openNewFolder}
                setOpen={setOpenNewFolder}
                projectId={projectId}
                dataSourceGroupId={group.id}
            />
            {isExpanded && (
                <div className="ml-6">
                    {group?.dataSources?.map((dataSource) => (
                        <DocumentItem
                            key={dataSource.id}
                            id={dataSource.id}
                            title={dataSource.name}
                            //  @ts-ignore
                            fileCount={dataSource.fileCount}
                            isSelected={selectedDatasets.has(dataSource.id)}
                            onToggle={onToggleDataset}
                            onUpload={() => {
                                navigate({
                                    to: '/$projectId/legal/$datasourceId',
                                    params: { projectId, datasourceId: dataSource.id },
                                })
                            }}
                            onDelete={() => handleDeleteDataset(dataSource.id)}
                            projectId={projectId}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
