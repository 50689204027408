import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useNavigate } from '@tanstack/react-router'
import { Folder, MoreHorizontal, Trash, Upload } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DocumentItemProps {
    id: string
    title: string
    fileCount?: number
    isSelected: boolean
    onToggle: (id: string) => void
    onUpload: (id: string) => void
    onDelete: () => void
    projectId: string
}

export const DocumentItem: React.FC<DocumentItemProps> = ({
    id,
    title,
    fileCount,
    isSelected,
    onToggle,
    onUpload,
    onDelete,
    projectId,
}) => {
    const [isHovered, setIsHovered] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div
            className="flex items-center justify-between py-3 border-b last:border-b-0"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex items-center">
                {isHovered ? (
                    <button
                        onClick={() => onUpload(id)}
                        className="w-5 h-5 mr-3 text-blue-500 hover:text-blue-600 transition-colors duration-200"
                    >
                        <Upload className="w-5 h-5" />
                    </button>
                ) : (
                    <Folder className="w-5 h-5 mr-3 text-gray-400" />
                )}
                <div
                    className=" hover:cursor-pointer"
                    onClick={() =>
                        navigate({
                            to: '/$projectId/legal/$datasourceId',
                            params: { projectId, datasourceId: id },
                        })
                    }
                >
                    <p className="text-sm font-medium">{title}</p>
                    <p className="text-xs text-gray-500">{fileCount} files</p>
                </div>
            </div>
            <div className="flex items-center gap-2">
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0 hover:text-black">
                            <span className="sr-only fixed">{t('open_menu')}</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel className="flex gap-1">{t('actions')}</DropdownMenuLabel>
                        <DropdownMenuItem onClick={onDelete} className="flex gap-1 text-red-500">
                            <Trash className="h-4 w-4" />
                            Delete Dataset
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
                <button
                    className={`w-5 h-5 rounded-full ${
                        isSelected ? 'bg-green-500' : 'bg-gray-200'
                    } flex items-center justify-center transition-colors duration-200`}
                    onClick={() => onToggle(id)}
                >
                    {isSelected && (
                        <svg
                            className="w-3 h-3 text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20 6L9 17L4 12"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </button>
            </div>
        </div>
    )
}
