import { Button } from '@/components/ui/button.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import * as Tabs from '@radix-ui/react-tabs'
import { MessageCircleQuestion, MessageCircleWarning, MessagesSquare, X } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HelpCenterChat } from './helpCenterChat'
import { UserFeedback } from './userFeedback'

export const HelpCenter = () => {
    const { t } = useTranslation()

    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('chat')

    return (
        <DropdownMenu open={dropDownOpen} onOpenChange={setDropDownOpen}>
            <DropdownMenuTrigger asChild>
                <Button variant={'ghost'} size={'icon'}>
                    <MessageCircleQuestion className={dropDownOpen ? 'text-primary' : ''} />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align={'end'} className={'w-[411px] bg-background p-0'}>
                <div className="flex justify-center gap-4 pt-4">
                    <Button
                        className="absolute top-2 left-2 text-black"
                        variant={'textOnly'}
                        onClick={() => setDropDownOpen(false)}
                    >
                        <X className="w-4"></X>
                    </Button>
                    <h1 className="font-bold text-sm">{t('help_center.title')}</h1>
                </div>
                <Tabs.Root value={activeTab} onValueChange={setActiveTab} className="flex flex-col">
                    <Tabs.List className="shrink-0 flex border-b border-mauve6 w-full gap-10">
                        <Tabs.Trigger
                            value="chat"
                            className="bg-background px-1 h-[52px] w-1/2 flex items-center justify-center text-sm leading-none select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary gap-2"
                        >
                            <MessagesSquare className="w-4"></MessagesSquare>
                            <p>{t('help_center.chat')}</p>
                        </Tabs.Trigger>
                        <Tabs.Trigger
                            value="feedback"
                            className="bg-background px-1 h-[52px] w-1/2 flex items-center justify-center text-sm leading-none select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary gap-2"
                        >
                            <MessageCircleWarning className="w-4"></MessageCircleWarning>
                            <p>{t('help_center.feedback')}</p>
                        </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content value="chat" className="flex-grow flex flex-col">
                        <HelpCenterChat />
                    </Tabs.Content>
                    <Tabs.Content value="feedback" className="flex-grow flex flex-col">
                        <UserFeedback onClose={() => setDropDownOpen(false)}></UserFeedback>
                    </Tabs.Content>
                </Tabs.Root>
                <div className="px-4 pb-4 pt-2 flex justify-center gap-2">
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">
                            {t('help_center.need_help')}
                            &nbsp;
                            <a
                                href="https://airia.com/explore"
                                target="_blank"
                                className="text-blue-500"
                            >
                                {t('help_center.help_center')}
                            </a>
                        </p>
                    </div>
                </div>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
