import {
    IdentityProvider,
    createIdentityProvider,
    getIdentityProvider,
    updateIdentityProvider,
} from '@/api/identityProviders'
import SelectOption from '@/components/settings/SelectOption.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Card, CardContent, CardFooter } from '@/components/ui/card.tsx'
import { Input } from '@/components/ui/input.tsx'
import { Switch } from '@/components/ui/switch'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { QueryKeys } from '@/constants/QueryKeys'
import { useMutation, useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from '../ui/use-toast'

const SSO: React.FC = () => {
    const [usePkce, setUsePkce] = useState('false')
    const [pkceMethod, setPkceMethod] = React.useState<string>('plain')
    const [providerId, setProviderId] = React.useState<string>('oidc')
    const [alias, setAlias] = React.useState<string>('')

    const getIdentityProviderQuery = useQuery({
        queryKey: [QueryKeys.IDENTITY_PROVIDER],
        queryFn: () => getIdentityProvider(),
        retry: 0,
    })

    const { handleSubmit, register, reset, setValue } = useForm<IdentityProvider>({
        defaultValues: { ...getIdentityProviderQuery.data },
        mode: 'onChange',
    })

    useEffect(() => {
        const data = getIdentityProviderQuery.data
        if (data) {
            setAlias(data.alias)
            setUsePkce(data.config?.pkceEnabled ? 'true' : 'false')
            setPkceMethod(data.config?.pkceMethod || 'plain')
            reset(getIdentityProviderQuery.data)
        }
    }, [getIdentityProviderQuery.data])

    const identityProviderMutation = useMutation({
        mutationFn: async (data: IdentityProvider) => {
            data.alias = alias
            data.providerId = providerId
            data.config.pkceEnabled = usePkce
            data.config.pkceMethod = pkceMethod
            if (data.alias) {
                await updateIdentityProvider(data.alias, data)
            } else {
                const result = await createIdentityProvider(data)
                setAlias(result.alias)
            }
        },
        onSuccess: () => {
            toast({
                title: t('success_title'),
                description: 'SSO settings updated successfully',
            })
        },
        onError: (error) => {
            toast({
                title: t('error_title'),
                description: error.message,
                variant: 'destructive',
            })
        },
    })

    const onPkceMethodSelect = (value: string) => {
        setPkceMethod(value)
    }

    useEffect(() => {
        setValue('config.pkceEnabled', usePkce)
    }, [usePkce])

    const onFormSubmit = async (data: IdentityProvider) => {
        identityProviderMutation.mutate(data)
    }

    return (
        <div className="flex flex-col gap-6 mt-6">
            <h1 className="text-2xl font-gilroy-regular">Single Sign On</h1>
            <div>
                <p>
                    Setup SSO for your organization using either SAML2.0 or OpenID Connect (OIDC)
                    protocols.
                </p>
                <div>
                    <a
                        className={'text-primary hover:underline'}
                        href="https://en.wikipedia.org/wiki/Single_sign-on"
                        target="_blank"
                    >
                        Learn more about SSO
                    </a>
                </div>
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="bg-card rounded-lg w-[65%]">
                    <Tabs defaultValue={providerId}>
                        <TabsList className="grid w-full px-6 py-4 bg-card h-auto">
                            <div className="bg-background flex-col p-1 rounded-full">
                                <TabsTrigger
                                    value="oidc"
                                    className="rounded-full data-[state=active]:bg-card data-[state=active]:text-foreground transition-all w-40"
                                    onClick={() => setProviderId('oidc')}
                                >
                                    OIDC
                                </TabsTrigger>
                                <TabsTrigger
                                    value="saml"
                                    className="rounded-full data-[state=active]:bg-card data-[state=active]:text-foreground transition-all w-40"
                                    onClick={() => setProviderId('saml')}
                                >
                                    SAML
                                </TabsTrigger>
                            </div>
                        </TabsList>
                        <TabsContent value="oidc">
                            <Card className="border-0 shadow-none bg-card">
                                <CardContent className="space-y-2 flex gap-6 flex-col">
                                    <div className="space-y-1">
                                        <label htmlFor="displayName">Display name</label>
                                        <Input
                                            id="displayName"
                                            type="text"
                                            className="h-10"
                                            placeholder="Enter name"
                                            {...register('displayName', { required: true })}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="authorizationUrl" className="text-base">
                                            Authorization URL
                                        </label>
                                        <Input
                                            id="authorizationUrl"
                                            type="text"
                                            className="h-10"
                                            placeholder="Paste URL here"
                                            {...register('config.authorizationUrl', {
                                                required: providerId === 'oidc',
                                            })}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="tokenUrl">Token URL</label>
                                        <Input
                                            id="tokenUrl"
                                            type="text"
                                            className="h-10"
                                            placeholder="Paste URL here"
                                            {...register('config.tokenUrl', {
                                                required: providerId === 'oidc',
                                            })}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label>PKCE</label>
                                        <div className="flex flex-col bg-card gap-4 p-4">
                                            <div className="flex">
                                                <label
                                                    htmlFor="usePkce"
                                                    className="text-base flex-auto"
                                                >
                                                    Use PKCE
                                                    <div className="text-sm">
                                                        Some additional text if needed
                                                    </div>
                                                </label>
                                                <Switch
                                                    id="usePkce"
                                                    checked={usePkce === 'true'}
                                                    onCheckedChange={(checked) =>
                                                        setUsePkce(checked ? 'true' : 'false')
                                                    }
                                                />
                                            </div>
                                            {usePkce && usePkce === 'true' && (
                                                <div>
                                                    <label
                                                        htmlFor="pkceMethod"
                                                        className="text-base"
                                                    >
                                                        PKCE Method
                                                    </label>

                                                    <div className={'flex gap-4'}>
                                                        <SelectOption
                                                            checked={pkceMethod === 'plain'}
                                                            value={'plain'}
                                                            onChange={onPkceMethodSelect}
                                                            label={'plain'}
                                                        />
                                                        <SelectOption
                                                            checked={pkceMethod === 'S256'}
                                                            value={'S256'}
                                                            onChange={onPkceMethodSelect}
                                                            label={'S256'}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="clientID" className="text-base">
                                            Client ID
                                        </label>
                                        <Input
                                            id="clientID"
                                            type="text"
                                            className="h-10"
                                            placeholder="Enter ID"
                                            {...register('config.clientId', {
                                                required: providerId === 'oidc',
                                            })}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="clientSecret" className="text-base">
                                            Client Secret
                                        </label>
                                        <Input
                                            id="clientSecret"
                                            type="password"
                                            className="h-10"
                                            placeholder="Enter Secret"
                                            {...register('config.clientSecret', {
                                                required: providerId === 'oidc',
                                            })}
                                        />
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <Button className="ml-auto">{t('save')}</Button>
                                </CardFooter>
                            </Card>
                        </TabsContent>
                        <TabsContent value="saml">
                            <Card className="border-0 shadow-none bg-card">
                                <CardContent className="space-y-2 flex gap-6 flex-col">
                                    <div className="space-y-1">
                                        <label htmlFor="displayName" className="text-base">
                                            Display name
                                        </label>
                                        <Input
                                            id="displayName"
                                            type="text"
                                            className="h-10"
                                            placeholder="Enter name"
                                            {...register('displayName', { required: true })}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="entityId">Server provider entity ID</label>
                                        <Input
                                            id="entityId"
                                            type="text"
                                            className="h-10"
                                            placeholder="Enter ID"
                                            {...register('config.entityId', {
                                                required: providerId === 'saml',
                                            })}
                                        />
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <Button className="ml-auto">{t('save')}</Button>
                                </CardFooter>
                            </Card>
                        </TabsContent>
                    </Tabs>
                </div>
            </form>
        </div>
    )
}

export default SSO
