import { getActiveTenant, getOrganizations, setTenant } from '@/api/workspaces.ts'
import Airplane from '@/assets/icons/airplane-tenancy.svg?react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '@/components/ui/dialog.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { t } from 'i18next'
import { Building2, Check, Loader2, RocketIcon } from 'lucide-react'
import React, { useEffect, useState } from 'react'

interface WorkspaceSelectorProps {}

export const WorkspaceSelector: React.FC<WorkspaceSelectorProps> = () => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const [tenantSwitchStatus, setTenantSwitchStatus] = useState({
        isSwitching: false,
        switched: false,
    })
    const [isOpen, setIsOpen] = React.useState(false)
    const [tenantSelectorVisible, setTenantSelectorVisible] = React.useState(false)

    const getOrganizationsQuery = useQuery({
        queryKey: [QueryKeys.ORGANIZATIONS],
        queryFn: () => getOrganizations(),
    })

    const getActiveTenantQuery = useQuery({
        queryKey: [QueryKeys.ACTIVE_TENANT],
        queryFn: () => getActiveTenant(),
    })

    useEffect(() => {
        if (getOrganizationsQuery.data) {
            const multipleTenants =
                getOrganizationsQuery.data.length > 1 ||
                getOrganizationsQuery.data.some((organization) => organization.tenants.length > 1)

            setTenantSelectorVisible(multipleTenants)
        }
    }, [getOrganizationsQuery.data])

    const setUserTenant = async (organizationId: string, tenantId: string) => {
        // set the tenant for the user
        console.log('Setting the tenant for the user')
        const tenantSwitchResult = await setTenant(organizationId, tenantId)
        console.log('Tenant switch status code: ', tenantSwitchResult)
        if (tenantSwitchResult) {
            setTenantSwitchStatus({ isSwitching: true, switched: false })
            // Invalidates all queries
            await queryClient.invalidateQueries()
            await router.invalidate()
            setTenantSwitchStatus({ isSwitching: false, switched: true })
        } else {
            //Closes the modal. The user is most likely attempting to switch to the same tenant.
            toast({
                title: t('error_title'),
                description: t('workspaces.error_description'),
                variant: 'destructive',
            })
            setIsOpen(false)
        }
    }

    return (
        tenantSelectorVisible && (
            <Dialog modal open={isOpen} onOpenChange={setIsOpen}>
                <DialogTrigger>
                    <Airplane className={'bg-[#4AC9A3] rounded-sm'} />
                </DialogTrigger>
                <DialogContent className={'min-h-64 max-w-[40%] px-4 py-3'}>
                    <DialogHeader>{t('workspaces.select_workspace')}</DialogHeader>
                    {tenantSwitchStatus.isSwitching ? (
                        <div className="flex flex-col w-full">
                            <Alert>
                                <RocketIcon className="h-4 w-4 mt-2" />
                                <AlertTitle>
                                    {t('workspaces.switching_workspace_header')}
                                </AlertTitle>
                                <AlertDescription>
                                    {t('workspaces.switching_workspace_message')}
                                </AlertDescription>
                            </Alert>
                            <Loader2 className="h-8 w-8 my-8 animate-spin self-center" />
                        </div>
                    ) : tenantSwitchStatus.switched ? (
                        <div className="flex flex-col w-full">
                            <Alert>
                                <Check className="h-4 w-4 mt-2" />
                                <AlertTitle>{t('workspaces.workspace_switched_header')}</AlertTitle>
                                <AlertDescription>
                                    {t('workspaces.workspace_switched_message')}
                                </AlertDescription>
                            </Alert>
                            {/*Navigates to the base path replacing navigation history.*/}
                            <Button
                                className="flex justify-items-center basis-1/2 my-8"
                                onClick={() => window.location.replace(router.basepath.toString())}
                            >
                                {t('workspaces.access_new_workspace')}
                            </Button>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-y-4 p-6">
                            {getOrganizationsQuery.data
                                ? getOrganizationsQuery.data.map((organization, _) => (
                                      <div
                                          key={organization.id}
                                          className="p-3 rounded-lg flex flex-1 flex-col gap-3 bg-background border-2 border-dotted border-separate"
                                      >
                                          <h3 className="text-base font-bold" key={organization.id}>
                                              {organization.displayName}
                                          </h3>
                                          <div
                                              key={'wrapper' + organization.id}
                                              className="flex flex-auto basis-1/4 justify-items-stretch content-center"
                                          >
                                              {organization.tenants.map((tenant, _) => (
                                                  <div key={tenant.id} className="flex grow shrink">
                                                      <Button
                                                          disabled={
                                                              getActiveTenantQuery.data?.id ===
                                                              tenant.id
                                                          }
                                                          className="grow shrink w-16 h-16 mr-2 hover:border-primary break-all truncate"
                                                          variant={'outline'}
                                                          key={tenant.id}
                                                          onClick={() =>
                                                              setUserTenant(
                                                                  organization.externalId,
                                                                  tenant.id
                                                              )
                                                          }
                                                      >
                                                          <Building2 className="mr-2 h-6 w-6" />
                                                          {tenant.identifier}
                                                      </Button>
                                                  </div>
                                              ))}
                                          </div>
                                      </div>
                                  ))
                                : t('loading')}
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        )
    )
}
