// pt.ts

export const portugueseTranslation = {
    home: 'Lar',
    pipelines: 'Pipelines',
    models: 'Modelos',
    datasets: 'Conjuntos de dados',
    prompts: 'Prompts',
    api_keys: 'Chaves API',
    memory: 'Memória',
    administration: 'Administração',
    language: 'Idioma',
    english: 'Inglês',
    spanish: 'Espanhol',
    italian: 'Italiano',
    german: 'Alemão',
    french: 'Francês',
    portuguese: 'Português',
    logout: 'Sair',
    toggle_theme: 'Alternar tema',
    error_occurred: 'Ocorreu um erro',
    airia_platform: 'Plataforma Airia',
    login: 'Entrar',
    uptime: 'Tempo de atividade',
    costs_per_month: 'Custos / mês',
    prompts_per_month: 'Prompts / mês',
    at: 'às',
    add_pipeline_step: 'Adicionar etapa de pipeline',
    objects: 'Objetos',
    model: 'Modelo',
    dataset: 'Conjunto de dados',
    actions: 'Ações',
    pipeline: 'Pipeline',
    code_execution: 'Execução de código',
    recall_memory: 'Recuperar memória',
    store_memory: 'Armazenar memória',
    loading: 'Carregando',
    max_number_of_results: 'Número máx de resultados',
    relevance_percentage: '% Relevância',
    input: 'Entrada',
    load: 'Carregar',
    store: 'Armazenar',
    search: 'Procurar',
    select_a_type: 'Selecionar um tipo',
    temperature: 'Temperatura',
    select_prompt: 'Selecionar um prompt...',
    search_prompts: 'Procurar prompts...',
    no_model_found: 'Nenhum modelo encontrado.',
    node_info: 'Informações do nó',
    delete: 'Excluir',
    name: 'Nome',
    guid: 'GUID',
    step_guid: 'GUID da etapa',
    api_url: 'URL da API',
    api_key: 'Chave API',
    output: 'Saída',
    version: 'Versão',
    error_title: 'Oh não! Algo deu errado.',
    error_description: 'Algo deu errado ao tentar criar o pipeline.',
    pipeline_name: 'Nome do pipeline',
    name_validation: 'O nome só pode conter letras, dígitos, espaços e os caracteres - ~ . _',
    other: 'Outro',
    toggle: 'Alternar',
    playground: 'Playground',
    try_out_pipeline: 'Experimente seu pipeline',
    request: 'Solicitação',
    ask_anything: 'Pergunte qualquer coisa',
    save_as: 'Salvar como',
    save: 'Salvar',
    unique_pipeline_name: 'O novo nome do pipeline deve ser único.',
    save_new_pipeline: 'Salvar novo pipeline',
    create_new_pipeline: 'Isso criará um novo pipeline com a configuração atual.',
    new_pipeline_name: 'Nome do novo pipeline',
    python_code_execution: 'Execução de código Python',
    view_api_key: 'Ver chave API',
    created_date: 'Data de criação',
    error_description_api: 'Algo deu errado ao tentar excluir a chave API.',
    open_menu: 'Abrir menu',
    see_api_key: 'Ver chave API',
    delete_api_key: 'Excluir chave API',
    new_api_key: 'Nova chave API',
    create_new_api_key: 'Criar nova chave API',
    key: 'Chave',
    create: 'Criar',
    data_source: 'Fonte de dados',
    select_data_source: 'Selecionar uma fonte de dados',
    azure_blob_storage: 'Azure Blob Storage',
    container_name: 'Nome do contêiner',
    database: 'Banco de dados',
    select_database: 'Selecionar um banco de dados',
    pinecone_api_key: 'Chave API do Pinecone',
    pinecone_index_name: 'Nome do índice Pinecone',
    connection_string: 'String de conexão',
    embedding_model: 'Modelo de incorporação',
    select_embedding_model: 'Selecionar um modelo de incorporação',
    chunking_strategy: 'Estratégia de divisão',
    select_chunking_strategy: 'Selecionar uma estratégia de divisão',
    airia_magic: 'Magia do Airia',
    custom: 'Personalizado',
    markdown: 'Markdown',
    recursive: 'Recursivo',
    html: 'HTML',
    token: 'Token',
    character: 'Caractere',
    chunk_size: 'Tamanho do segmento',
    chunk_overlap: 'Sobreposição de segmento',
    separator: 'Separador',
    encoding_name: 'Nome da codificação',
    reprocess: 'Reprocessar',
    update: 'Atualizar',
    loginPage: {
        header: 'Acelere sua jornada de IA',
        enterEmail: 'Digite seu e-mail',
        continue: 'Continuar',
        newToAiria: 'Novo em Airia?',
        getStarted: 'Começar',
        problemLoggingIn: 'Problemas para fazer login?',
    },
    error_description_model: 'Algo deu errado ao tentar atualizar o modelo.',
    columns: {
        name: 'Nome',
        executionName: 'Nome da execução',
        status: 'Status',
        lastUpdated: 'Última atualização',
    },
    actions_data_source: {
        openMenu: 'Abrir menu',
        label: 'Ações',
        edit: 'Editar fonte de dados',
        delete: 'Excluir fonte de dados',
    },
    data_source_title: 'Fontes de dados',
    newDataSource: 'Nova fonte de dados',
    createDataset: {
        title: 'Criar novo conjunto de dados',
        dataSource: 'Fonte de dados',
        selectDataSource: 'Selecionar uma fonte de dados',
        name: 'Nome',
        namePatternTitle: 'O nome só pode conter letras, dígitos, espaços e os caracteres - ~ . _',
        containerName: 'Nome do contêiner',
        database: 'Banco de dados',
        selectDatabase: 'Selecionar um banco de dados',
        pineconeApiKey: 'Chave API do Pinecone',
        pineconeIndexName: 'Nome do índice Pinecone',
        connectionString: 'String de conexão',
        embeddingModel: 'Modelo de incorporação',
        selectEmbeddingModel: 'Selecionar um modelo de incorporação',
        chunkingStrategy: 'Estratégia de divisão',
        selectChunkingStrategy: 'Selecionar uma estratégia de divisão',
        airiaMagic: 'Magia do Airia',
        custom: 'Personalizado',
        markdown: 'Markdown',
        recursive: 'Recursivo',
        html: 'HTML',
        token: 'Token',
        character: 'Caractere',
        chunkSize: 'Tamanho do segmento',
        chunkOverlap: 'Sobreposição de segmento',
        separator: 'Separador',
        encoding_name: 'Nome da codificação',
        create: 'Criar',
    },
    editMemory: {
        success_title: 'Sucesso!',
        success_description_prompt: 'Memória atualizada com sucesso.',
        error_title: 'Oh não! Algo deu errado.',
        error_description: 'Algo deu errado ao tentar atualizar a memória.',
        duplicateNameError: 'Já existe uma memória com o mesmo nome nesta aplicação.',
        name: 'Nome',
        type: 'Tipo',
        selectType: 'Selecionar um tipo',
        submit: 'Enviar',
        types: {
            user: 'Usuário',
            conversation: 'Conversa',
        },
    },
    editModel: {
        success_title: 'Sucesso!',
        success_description_prompt: 'Modelo atualizada com sucesso.',
        error_title: 'Oh não! Algo deu errado.',
        error_description: 'Algo deu errado ao tentar atualizar a modelo.',
        displayName: 'Nome de exibição',
        provider: 'Provedor de modelo',
        selectProvider: 'Selecionar um provedor de modelo',
        endpoint: 'Ponto de extremidade do modelo',
        apiKey: 'Chave API',
        modelName: 'Nome do modelo',
        modelInput: 'Entrada do modelo',
        selectType: 'Selecionar um tipo de entrada do modelo',
        systemPrompt: 'Prompt do sistema',
        selectSystemPrompt: 'Select a System Prompt',
        searchPrompts: 'Search Prompts...',
        noModelFound: 'Nenhum modelo encontrado.',
        submit: 'Enviar',
        types: {
            text: 'Texto',
            image: 'Imagem',
        },
    },
    memoryIndex: {
        memory: 'Memória',
        newMemory: 'Nova memória',
        openMenu: 'Abrir menu',
        actions: 'Ações',
        editMemory: 'Editar memória',
        deleteMemory: 'Excluir memória',
    },
    newMemory: {
        name: 'Nome',
        type: 'Tipo',
        selectType: 'Selecionar um tipo',
        submit: 'Enviar',
        types: {
            user: 'Usuário',
            conversation: 'Conversa',
        },
        duplicateNameError: 'Já existe uma memória com o mesmo nome nesta aplicação.',
        generalError: 'Ocorreu um erro ao criar a memória.',
    },
    newModel: {
        displayName: 'Nome de exibição',
        provider: 'Provedor de modelo',
        selectProvider: 'Selecionar um provedor de modelo',
        endpoint: 'Ponto de extremidade do modelo',
        apiKey: 'Chave API',
        modelName: 'Nome do modelo',
        modelInput: 'Entrada do modelo',
        selectType: 'Selecionar um tipo de entrada do modelo',
        systemPrompt: 'Prompt do sistema',
        selectSystemPrompt: 'Select a System Prompt',
        searchPrompts: 'Search Prompts...',
        noModelFound: 'Nenhum modelo encontrado.',
        submit: 'Enviar',
        types: {
            text: 'Texto',
            image: 'Imagem',
        },
    },
    columns_models: {
        name: 'Nome',
        executionName: 'Nome da execução',
        lastUpdated: 'Última atualização',
    },
    actions_models: {
        openMenu: 'Abrir menu',
        label: 'Ações',
        edit: 'Editar modelo',
        delete: 'Excluir modelo',
    },
    create_new_model: 'Criar novo modelo',
    text: 'Texto',
    image: 'Imagem',
    save_changes: 'Salvar alterações',
    edit: 'Editar',
    success_title: 'Sucesso!',
    pipeline_updated_successfully: 'Pipeline atualizado com sucesso.',
    error_description_pipeline: 'Algo deu errado ao tentar atualizar o pipeline.',
    columns_pipelines: {
        name: 'Nome',
        status: 'Status',
        version: 'Versão',
        modified: 'Modificado',
        stats: 'Estatísticas',
    },
    actions_pipelines: {
        label: 'Ações',
        edit: 'Editar pipeline',
        connection_info: 'Informações de conexão',
        delete: 'Excluir pipeline',
    },
    sort_by: 'Ordenar por',
    date_modified: 'Data de modificação',
    new_pipeline: 'Novo pipeline',
    status_ready: 'Pronto',
    success_description_prompt: 'Prompt atualizado com sucesso.',
    error_description_prompt: 'Algo deu errado ao tentar atualizar o prompt.',
    select_version: 'Selecionar versão',
    active: 'Ativo',
    set_active: 'Definir como ativo',
    description: 'Descrição',
    messages: 'Mensagens',
    add_message: 'Adicionar mensagem',
    new_prompt: 'Novo prompt',
    active_version: 'Versão ativa',
    latest_version: 'Última versão',
    last_modified: 'Última modificação',
    unknown_date: 'Data desconhecida',
    edit_prompt: 'Editar prompt',
    delete_prompt: 'Excluir prompt',
    delete_prompt_confirmation: 'Tem certeza de que deseja excluir este prompt?',
    delete_prompt_error: 'Falha ao excluir o prompt:',
    error_description_create_prompt: 'Falha ao criar o prompt:',
    project: 'Aplicativo',
    projects: 'Aplicativos',
    applicaton_description: 'Crie e visualize aplicativos.',
    project_create: 'Criar aplicativo',
    project_view: 'Ver aplicativo',
    project_delete: 'Excluir aplicativo',
    back: 'Indietro',
    groups: 'Grupos',
    users: 'Usuários',
    permissions: 'Permissões',
    data_sources: 'Fontes de dados',
    workbench: 'Bancada',
    exit: 'Sair',
    add_component: 'Adicionar Componente',
    type_your_message: 'Digite sua mensagem...',
    prompt_versions: 'Versões de Prompt',
    select_a_model: 'Selecione um Modelo',
    select_a_prompt: 'Selecione um Prompt',
    select_a_prompt_version: 'Selecione uma Versão de Prompt',
    remove_chat_component: 'Remover Componente de Chat',
    clear_files: 'Limpar Arquivos',
    upload_files: 'Enviar Arquivos',
    send_message: 'Enviar Mensagem',
    page_size: 'Tamanho da Página',
    workspaces: {
        actions: {
            open_menu: 'Abrir menu',
            access_control: 'Controle de acesso',
        },
        workspace: 'Espaço de trabalho',
        workspaces: 'Espaços de trabalho',
        workspaces_description: 'Criar e visualizar espaços de trabalho.',
        workspace_create: 'Criar espaço de trabalho',
        access_control: 'Controle de acesso',
        workspace_create_success: 'Espaço de trabalho criado com sucesso.',
        select_workspace: 'Selecione o espaço de trabalho',
        switching_workspace_header: 'Quase lá!',
        switching_workspace_message: 'Mudando de espaço de trabalho, por favor, aguarde.',
        workspace_switched_header: 'Feito!',
        workspace_switched_message: 'Pronto para acessar o espaço de trabalho.',
        access_new_workspace: 'Acessar espaço de trabalho',
        error_description: 'Algo deu errado ao tentar trocar de espaço de trabalho.',
    },
} as const
